import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { confirmAlert } from "react-confirm-alert";

import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import Form from "react-bootstrap/Form";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  FaPencilAlt,
  FaTrash,
  FaCloudUploadAlt,
  FaPlus,
  FaBan,
  FaCheck,
} from "react-icons/fa";
import "react-notifications/lib/notifications.css";
import Button from "react-bootstrap/Button";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Loader from "../../components/Loader";

const GoogleAds = () => {
  const authToken = localStorage.getItem("token");
  const [generate, setGenerate] = useState(false);
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [data, setdata] = useState([]);
  const [logsdata, setLogsData] = useState([]);
  const [data_old, setdata_old] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userdata, setUserdata] = useState([]);

  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",
    ad_group_id: "",
    api_key: "",
  });
  const authCode = queryParams.get("refresh_token");
  useEffect(() => {
    getData();
    getAdslogslist();
    fetchAgentList();
  }, []);
  useEffect(() => {
    if(formData.client_id!=""){

      getAdslist(formData);
    }
  }, [formData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/datacred`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log(response.data);

      let newFormData = { ...formData };
      response.data.data.forEach((item) => {
        // console.log(item);
        switch (item.name) {
          case "client_id":
            newFormData["client_id"] = item.value;
            break;
          case "client_secret":
            newFormData["client_secret"] = item.value;
            break;
          case "developer_token":
            newFormData["developer_token"] = item.value;
            break;
          case "redirecturl":
            newFormData["redirecturl"] = item.value;
            break;
          case "refresh_token":
            newFormData["refresh_token"] = item.value;
            break;
          case "customer_id":
            newFormData["customer_id"] = item.value;
            break;
          case "manager_id":
            newFormData["manager_id"] = item.value;
            break;
          case "ad_group_id":
            newFormData["ad_group_id"] = item.value;
            break;
          case "api_key":
            newFormData["api_key"] = item.value;
            break;
          default:
            break;
        }
      });
      // getAdslist(newFormData);
      setFormData(newFormData);
      if (authCode) {
        setFormData({
          ...newFormData,
          ["refresh_token"]: authCode,
        });
        console.log("asdsadasd");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  // console.log(formData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ads/save`,
        {
          formData,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }

    // Handle the form data as needed, e.g., send to an API endpoint
  };
  const getAdslist = async (formdata) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ads/list`,
        { formData: formdata },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      console.log(response.data);
      setLoading(false);
      setdata_old(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const getAdslogslist = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/getalladslogs`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLogsData(response.data.result);
      console.log(response.data);
      //   setdata(response.data);
      //  setdata_old(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const isGenerateButtonVisible =
    formData.client_id && formData.client_secret && formData.redirecturl;

  const handleGenrate = async (event) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}gettoken`,
        {
          formData,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      // console.log(response);
      window.location.href = response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
 
  const handleConfirmBox = (id,resource_name, status) => {
    if(status==1){
      confirmAlert({
        title: <span className="orange-title">Confirm</span>,
        message: "Are you sure you want change status to enable ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              handleApproveClick(id,resource_name, status);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
    else if(status==3){
      confirmAlert({
        title: <span className="orange-title">Confirm</span>,
        message: "Are you sure you want to remove ad ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              handleApproveClick(id,resource_name, status);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
    else{
      confirmAlert({
        title: <span className="orange-title">Confirm</span>,
        message: "Are you sure you want change status to pause ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              handleApproveClick(id,resource_name, status);
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    }
    
    
  };
  const handleApproveClick = async (id,resource_name, status) => {
    setLoading(true);
    console.log(resource_name);
    const adGroupId = resource_name.split('/')[3].split('~')[0];
    console.log(adGroupId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ads/change-status/${id}/${status}`,
        {
          formData: formData,
          status: status,
          group_id:adGroupId,
          id: `${id}`,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        getAdslist(formData);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        // localStorage.clear();
        // navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.adGroupAd.ad.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable:true,
     cell: (row) =>(
      <a href={`/agentprofile/${row.user_id}`} target="_blank" style={{textDecoration:"none",textTransform:"capitalize"}}>{row.name}</a>
      ),
    },

    {
      name: "clicks",
      selector: (row) => row.metrics.clicks,
    },

    {
      name: "impression",
      selector: (row) => row.metrics.impressions,
    },
    {
      name: "userid",
      selector: (row) => row.user_id,
    },
    {
      name: "orderid",
      selector: (row) => row.order_id,
      sortable:true,
      cell: (row) =>(
        <a href={`/admin/orders/${row.order_id}`} target="_blank" style={{textDecoration:"none",textTransform:"capitalize"}}>{row.order_id}</a>
        ),
    },
    {
      name: "zipcode",
      selector: (row) => row.zipcode,
    },
    {
      name: "Status",
      selector: (row) => row.adGroupAd.status,
      cell: (row) =>
        row.adGroupAd.status == "ENABLED" ? (
          <Button
            title="Click to inactive"
            variant="success"
            size="sm"
            onClick={() => handleConfirmBox(row.adGroupAd.ad.id,row.adGroupAd.resourceName, 0)}
          >
            <FaCheck />
          </Button>
        ) : (
          <Button
            title="Click to active"
            variant="danger"
            size="sm"
            onClick={() => handleConfirmBox(row.adGroupAd.ad.id,row.adGroupAd.resourceName, 1)}
          >
            <FaBan />
          </Button>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <Button
            title="Delete"
            variant="danger"
            className="m-1"
            size="sm"
            onClick={() => handleConfirmBox(row.adGroupAd.ad.id,row.adGroupAd.resourceName, 3)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];
  const fetchAgentList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/list`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      setUserdata(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    if(data_old.length>0){
      const merged = data_old.map((item1) => {
        const match = logsdata.find((item2) => {
          if (item2?.ads_id) {
            const parsedObject = JSON.parse(item2.ads_id);
            // console.log(parsedObject);
            return parsedObject.resourceName==item1.adGroupAd.resourceName;
          }
         
          // console.log(item2.ads_id);
        });
        return match ? { ...item1, ...match } : item1;
      });
      const merged1 = merged.map((item1) => {
        const match = userdata.find((item2) => {
          console.log(item2);
          if (item2?.id) {
            // const parsedObject = JSON.parse(item2.ads_id);
            // console.log(parsedObject);
            return item2.id==item1.user_id;
          }
         
          // console.log(item2.ads_id);
        });
        return match ? { ...item1, ...match } : item1;
      });
      // console.log(merged);
      // setMergedArray(merged);
      setdata(merged1)
    }
    
  }, [data_old, logsdata]);
  console.log(data);
  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <h4>Google Ads List</h4>
              </Card.Header>
              <Card.Body>
                <div className="dataTable" style={{ margin: "13px 0px" }}>
                  <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    {/* <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      onChange={handlefilter}
                    /> */}
                  </div>
                  <DataTable
                    columns={columns}
                    data={data}
                    selectableRows
                    pagination
                    highlightOnHover
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* View Contact data modal */}
      </Container>
    </Dashboardlayout>
  );
};
export default GoogleAds;
