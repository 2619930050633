import React, { useState, useEffect } from "react";
import "../Style/home.css";
import "../Style/custom.css";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import usStates from "./usStates";
import Loader from "../components/Loader";
import ConstructionPage from "./ConstructionPage";
import dummyAgent from "../assets/agreement.png";
import { Helmet } from "react-helmet";

const CityPage = () => {
  const { state, district, city } = useParams();
  const navigate = useNavigate();
  const [agentList, setAgentList] = useState([]);
  const [cityInfo, setCityInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("token");
  const [splitContent, setSplitContent] = useState({});
  const statesPerColumn = Math.ceil(usStates.length / 4);
  const [defaultRange, setRange] = useState(1650000);
  const [onePer, setOnePer] = useState(0);
  const [twoPer, setTwoPer] = useState(0);
  const [threePer, setThreePer] = useState(0);
  useEffect(() => {
    setOnePer((defaultRange * 1) / 100);
    setTwoPer((defaultRange * 2) / 100);
    setThreePer((defaultRange * 3) / 100);
  }, [defaultRange]);

  const getCityPageData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}communities/${state}/${district}/${city}`
      );
      setLoading(false);
      if (response.status) {
        if (response.data.data) {
          setCityInfo(response.data.data.cityInfo);
          setAgentList(response.data.data.agentList);
          const { previewContent, remainingContent } = getContentSplit(
            response.data.data.cityInfo.content,
            900
          );
          setSplitContent({ previewContent, remainingContent });
        }
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };
  useEffect(() => {
    getCityPageData();
    window.scrollTo(0, 0);
  }, []);

  const handleLiClick = (value, label) => {};
  const convertToSlug = (text) => {
    return text.toLowerCase().replace(/ /g, "-");
  };

  const getContentSplit = (content, charLimit) => {
    if (content.length <= charLimit) {
      return { previewContent: content, remainingContent: "" };
    }

    let sliceEnd = content.lastIndexOf(".", charLimit);
    let safeSliceEnd =
      sliceEnd > charLimit / 2 ? sliceEnd : content.indexOf(".", charLimit);
    if (safeSliceEnd === -1) safeSliceEnd = content.length;

    const previewContent = content.slice(0, safeSliceEnd + 1);
    const remainingContent = content.slice(safeSliceEnd + 1);

    return { previewContent, remainingContent };
  };
  function unslug(text) {
    const stringWithSpaces = text.replace(/-/g, " ");
    const stringWithTitleCase = stringWithSpaces
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return stringWithTitleCase;
  }

  if (loading || !cityInfo) {
    return <Loader />;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handlePriceChange = (event) => {
    setRange(event.target.value);
    setOnePer((event.target.value * 1) / 100);
    setTwoPer((event.target.value * 2) / 100);
    setThreePer((event.target.value * 3) / 100);
  };

  return (
    <Layout>
      <Helmet>
        <title>{`Local 1% Listing Agents In ${cityInfo.name}, ${unslug(
          state
        )} | Listelligent`}</title>
        {/* <meta name="description" content={description} /> */}
      </Helmet>
      {loading && <Loader />}
      <div className="first-section">
        <NotificationContainer />
        <Container>
          <Row className="mt-3 first-inner-section ">
            <Col>
              <Card className="mb-4 border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading mb-4">
                    <h1 className="orangeColorText">
                      Local 1% Listing Agents In {cityInfo.name},{" "}
                      {unslug(state)}
                    </h1>
                  </Card.Title>
                  <Row style={{ textAlign: "justify" }}>
                    <Col md={8}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.previewContent,
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      {cityInfo.img ? (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/state/${cityInfo.img}`}
                        />
                      ) : (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={require("../assets/no-image.png")}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.remainingContent,
                        }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="second-section">
          <Container>
            <Row>
              <Col md={12}>
                <div className="communitiesHeading">
                  <h2 className="textColor">
                    How much can I save by using a Listelligent pro?
                  </h2>
                  <h2 className="mt-4 textColor">Sales Price</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={3}></Col>
              <Col md={6} className="rang-label">
                <Form.Label>Listing Price</Form.Label>
                <center>
                  <h4>{formatCurrency(defaultRange)}</h4>
                </center>
                <Form.Range
                  min={300000}
                  max={3000000}
                  step={10}
                  value={defaultRange}
                  onChange={handlePriceChange}
                />
                <div className="d-flex justify-content-between">
                  <h5>{formatCurrency(300000)}</h5>
                  <h5>{formatCurrency(3000000)}</h5>
                </div>
              </Col>
              <Col md={3}></Col>
            </Row>
            <Row className="calculation-colunms justify-content-center">
              <Col md={6} sm={12} className="calculator-stat-listelligent ">
                <p>Agent fees with listelligent</p>
                <p>1%</p>
                <h3>{formatCurrency(onePer)}</h3>
              </Col>
              <Col md={3} sm={12} className="calculator-stat-others">
                <p>Other Agents</p>
                <p>3%</p>
                <h3>{formatCurrency(threePer)}</h3>
              </Col>
              <Col md={3} sm={12} className="calculator-stat-saving">
                <p>Potential Savings:</p>
                <h3>{formatCurrency(twoPer)}</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5">
          <Container>
            <div className="communitiesHeading">
              <h2 className="orangeColorText">Your Local 1% Listing Agent</h2>
            </div>
            <Row>
              {agentList.map((result, index) => (
                <Col key={index} md={6}>
                  <div className="agent-profile">
                    <Row className="d-flex align-items-center">
                      <Col md={3} className="agent-img">
                        {result.agentData && result.agentData.profile !== "" ? (
                          <img
                            alt="agent"
                            src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${result.agentData.profile}`}
                          ></img>
                        ) : (
                          <img alt="agent" src={dummyAgent} />
                        )}
                      </Col>
                      <Col md={9}>
                        <div className="agentlist-btn mb-3 m-1">
                          <Link
                            to={`/agentprofile/${result.id}`}
                            style={{ fontSize: "20px" }}
                          >
                            {result.name}
                          </Link>
                        </div>
                        <p className="m-3 h4">
                          <span>{result.brokerage}</span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
              {agentList.length === 0 && <p>No agents found.</p>}
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default CityPage;
