import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Accordion from "react-bootstrap/Accordion";
import TimeAgo from "react-timeago";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/Loader";

const Orders = () => {
  const routes = useParams();

  // console.log(routes);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data_old, setdata_old] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [loading1, setLoading1] = useState(true);

  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",

    cpc: "",
    api_key: "",
  });
  const handleStatusUpdate = async (id, status, row) => {
    setLoading(true);
    // console.log(row);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/status/order/${id}/${status}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        getAdminOrderList();
        googleadschangestatus(id, status, row);
        NotificationManager.success("Success", response.data.message, 3000);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const getStatusDivStyle = (status) => {
    switch (status) {
      case 0:
        return { backgroundColor: "#6c757d", color: "#fff" }; // New
      case 1:
        return { backgroundColor: "#28a745", color: "#fff" }; // Complete
      case 2:
        return { backgroundColor: "#dc3545", color: "#fff" }; // Decline
      default:
        return { backgroundColor: "#6c757d", color: "#fff" }; // Unknown
    }
  };
  const getColor = (status) => {
    switch (status) {
      case 0:
        return { color: "#6c757d" }; // New
      case 1:
        return { color: "#28a745" }; // Complete
      case 2:
        return { color: "#dc3545" }; // Decline
      default:
        return { color: "#6c757d" }; // Unknown
    }
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Total amount",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user.name,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
    },
    {
      name: "Created at",
      selector: (row) => <TimeAgo date={row.created_at} />,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <div
          style={{
            padding: "4px",
            borderRadius: "4px",
            ...getStatusDivStyle(row.status),
          }}
        >
          {row.status === 0
            ? "New"
            : row.status === 1
            ? "Complete"
            : row.status === 2
            ? "Cancelled"
            : "Unknown"}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) =>
        row.status === 2 ? (
          <span>Cancelled</span>
        ) : (
          <DropdownButton
            id="dropdown-basic-button"
            variant="dark"
            title="Status"
          >
            <Dropdown.Item onClick={() => handleStatusUpdate(row.id, 0, row)}>
              <span style={getColor(0)}>Pending</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusUpdate(row.id, 1, row)}>
              <span style={getColor(1)}>Complete</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleStatusUpdate(row.id, 2, row)}>
              <span style={getColor(2)}>Cancel</span>
            </Dropdown.Item>
          </DropdownButton>
        ),
    },
  ];

  const [data, setData] = useState([]);
  const [records, setRecords] = useState(data);

  function handlefilter(event) {
    console.log(event?.target?.value?.toLowerCase());
    const searchTerm =
      event?.target?.value?.toLowerCase() != undefined
        ? event?.target?.value?.toLowerCase()
        : `${routes.order_id}`;
    console.log(searchTerm);
    const newData = data.filter((row) => {
      const rowValues = [
        ...Object.values(row),
        ...row.orderProduct.map((product) => Object.values(product)),
        Object.values(row.user),
      ].flat();

      return rowValues
        .filter((value) => value !== undefined && value !== null)
        .some((value) => value.toString().toLowerCase().includes(searchTerm));
    });

    setRecords(newData);
  }

  const getAdminOrderList = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/orders`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);

      setData(response.data.data);
      setRecords(response.data.data);
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleConfirmBox = (id, order_id) => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to cancel product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleSuProductDelete(id, order_id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleConfirmBoxCancel = (order_id) => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to cancel Subscription ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleCancelSubscription(order_id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleCancelSubscription = async (order_id) => {
    console.log("Cancelling subscription", order_id);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/cancel-subscription/${order_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        NotificationManager.success(
          "Subscription",
          response.data.message,
          3000
        );
        getAdminOrderList();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const googleadschangestatus = async (id, status, row) => {
    // console.log(id);
    // console.log(row);
    // console.log(status);
    if (!data_old || !data_old.result) {
      console.error("data_old or data_old.result is undefined");
      setLoading(false);
      NotificationManager.error("Error", "Data not found.", 3000);
      return;
    }
    const filteredData = data_old.result.filter(
      (item) => item.order_id === id && item.camp_id != null
    );
    console.log(filteredData);
    const groupid = filteredData[0]?.ads_id.split("/")[3].split("~")[0];
    const adid = filteredData[0].ads_id
      .split("/")[3]
      .split("~")[1]
      .replace(/\D/g, "");
    const real_status = status == 1 ?1:status==0?0:3;
    try {
      
        const response1 = await axios.post(
          `${process.env.REACT_APP_BASE_URL}ads/change-status/${adid}/${real_status}`,
          { formData: formData, status: real_status, group_id: groupid, id: adid },
          {
            headers: { Authorization: `Bearer ${authToken}` },
            withCredentials: true,
          }
        );
        console.log(response1);
        getAdminOrderList();
    
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const handleSuProductDelete = async (id, order_id) => {
    setLoading(true);
    if (!data_old || !data_old.result) {
      console.error("data_old or data_old.result is undefined");
      setLoading(false);
      NotificationManager.error("Error", "Data not found.", 3000);
      return;
    }
    const filteredData = data_old.result.filter(
      (item) => item.order_id === order_id && item.camp_id != null
    );
    console.log(filteredData);
    const groupid = filteredData[0]?.ads_id.split("/")[3].split("~")[0];
    const adid = filteredData[0].ads_id
      .split("/")[3]
      .split("~")[1]
      .replace(/\D/g, "");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/product-cancel/${id}/${order_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success(
          "Subscription",
          response.data.message,
          3000
        );
        const response1 = await axios.post(
          `${process.env.REACT_APP_BASE_URL}ads/change-status/${adid}/${3}`,
          { formData: formData, status: 3, group_id: groupid, id: adid },
          {
            headers: { Authorization: `Bearer ${authToken}` },
            withCredentials: true,
          }
        );
        console.log(response1);
        getAdminOrderList();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const getAdsLogslist = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/getalladslogs`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      // console.log(response.data);
      setdata_old(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/datacred`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      // console.log(response.data);

      let newFormData = { ...formData };
      response.data.data.forEach((item) => {
        // console.log(item);
        switch (item.name) {
          case "client_id":
            newFormData["client_id"] = item.value;
            break;
          case "client_secret":
            newFormData["client_secret"] = item.value;
            break;
          case "developer_token":
            newFormData["developer_token"] = item.value;
            break;
          case "redirecturl":
            newFormData["redirecturl"] = item.value;
            break;
          case "refresh_token":
            newFormData["refresh_token"] = item.value;
            break;
          case "customer_id":
            newFormData["customer_id"] = item.value;
            break;
          case "manager_id":
            newFormData["manager_id"] = item.value;
            break;
          case "ad_group_id":
            newFormData["ad_group_id"] = item.value;
            break;
          case "api_key":
            newFormData["api_key"] = item.value;
            break;
          default:
            break;
        }
      });
      // getAdslist(newFormData);
      setFormData(newFormData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    getAdminOrderList();
    getData();
    getAdsLogslist();
  }, []);
  useEffect(() => {
    if (
      routes.order_id &&
      loading1 == true &&
      loading == false &&
      data.length > 0
    ) {
      handlefilter();
      setLoading(false);
      setLoading1(false);

      console.log("asdd");

      // Cleanup the timeout if the component unmounts before the timeout completes
      // return () => clearTimeout(timer);

      // handlefilter();
    }
  }, [loading]);
  useEffect(() => {
    // Find the record with the defaultExpandedOrderId
    if (routes.order_id) {
      // console.log(records);
      const defaultExpandedRow = records.find(
        (record) => record.id === parseInt(routes.order_id)
      );
      // console.log(defaultExpandedRow);
      setExpandedRow(defaultExpandedRow);
    }
  }, [records, routes.order_id]);
  const styles = {
    border: "1px solid #2222225c",
  };
  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <h4>Orders</h4>
              </Card.Header>
              <Card.Body>
                <div className="dataTable" style={{ margin: "13px 0px" }}>
                  <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      defaultValue={routes?.order_id}
                      onChange={handlefilter}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={records}
                    // selectableRows
                    pagination
                    highlightOnHover
                    expandableRows
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                    expandableRowExpanded={(row) => row.id === expandedRow?.id}
                    expandableRowsComponent={({ data }) => {
                      return (
                        <Accordion
                          defaultActiveKey={
                            expandedRow && expandedRow.id === data.id ? 0 : null
                          }
                        >
                          <Accordion.Item
                            eventKey={0}
                            key={0}
                            className="accordian-items"
                            style={styles}
                          >
                            <Accordion.Header>
                              <p>
                                Total amount: &nbsp;<b>{data.total}</b>
                                &nbsp;&nbsp;
                              </p>
                              <span className="status-tag mb-3">
                                <TimeAgo date={data.created_at} />
                              </span>
                              {/* {data.status === 2 ? (
                                <Button
                                  className="mx-2 btn-sub find-btn mb-3 custom-button-orange-bg btn-dark"
                                  disabled
                                >
                                  Subscription Cancelled
                                </Button>
                              ) : (
                                <Button
                                  className="mx-2 btn-sub find-btn mb-3 custom-button-orange-bg"
                                  onClick={() =>
                                    handleConfirmBoxCancel(data.id)
                                  }
                                >
                                  Cancel Subscription
                                </Button>
                              )} */}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="imp-doc-accordian">
                                {data.orderProduct.length > 0 && (
                                  <div className="cartItem mb-5">
                                    <Row className="pb-3 border-bottom align-items-center">
                                      <Col lg={2} className="imp-doc-first-col">
                                        <p>
                                          <b>City</b>
                                        </p>
                                      </Col>
                                      <Col
                                        lg={2}
                                        className="imp-doc-second-col"
                                      >
                                        <p>
                                          <b>Zip code</b>
                                        </p>
                                      </Col>
                                      <Col lg={1} className="imp-doc-third-col">
                                        <p>
                                          <b>Price</b>
                                        </p>
                                      </Col>
                                      <Col lg={2} className="imp-doc-third-col">
                                        <p>
                                          <b>Start Subscription</b>
                                        </p>
                                      </Col>
                                      <Col lg={2} className="imp-doc-third-col">
                                        <p>
                                          <b>End Subscription</b>
                                        </p>
                                      </Col>
                                      <Col lg={1} className="imp-doc-third-col">
                                        <p>
                                          <b>Action</b>
                                        </p>
                                      </Col>
                                      <Col lg={2}>
                                        <p>
                                          <b>Remark</b>
                                        </p>
                                      </Col>
                                    </Row>
                                    {data.orderProduct.map((product, index) => (
                                      <Row
                                        className="pb-3 border-bottom align-items-center"
                                        key={index}
                                      >
                                        <Col
                                          lg={2}
                                          className="imp-doc-first-col"
                                        >
                                          <p>{product.city}</p>
                                        </Col>
                                        <Col
                                          lg={2}
                                          className="imp-doc-second-col"
                                        >
                                          <p>{product.zip_code}</p>
                                        </Col>
                                        <Col
                                          lg={1}
                                          className="imp-doc-third-col"
                                        >
                                          <p>{product.price}</p>
                                        </Col>
                                        <Col
                                          lg={2}
                                          className="imp-doc-third-col"
                                        >
                                          <p>{product.start_date}</p>
                                        </Col>
                                        <Col
                                          lg={2}
                                          className="imp-doc-third-col"
                                        >
                                          <p>{product.end_date}</p>
                                        </Col>
                                        <Col
                                          lg={1}
                                          className="imp-doc-third-col"
                                        >
                                          {product.status === 2 ||
                                          (product.isCancel !== "" &&
                                            product.isCancel !== null) ? (
                                            <Button
                                              className="p-2 btn-sub find-btn mb-3 custom-button-orange-bg btn-dark"
                                              disabled
                                            >
                                              Cancelled
                                            </Button>
                                          ) : (
                                            <Button
                                              className="btn-sub find-btn mb-3 custom-button-orange-bg"
                                              onClick={() =>
                                                handleConfirmBox(
                                                  product.id,
                                                  data.id
                                                )
                                              }
                                            >
                                              Cancel
                                            </Button>
                                          )}
                                        </Col>
                                        <Col lg={2}>
                                          <p className="mx-2">
                                            {product.isCancel !== "" &&
                                              product.isCancel === 1 && (
                                                <span>
                                                  End at {product.end_date}
                                                </span>
                                              )}
                                            {product.isCancel !== "" &&
                                              product.isCancel === 0 && (
                                                <span>
                                                  Ended by admin on{" "}
                                                  {product.cancel_at}
                                                </span>
                                              )}
                                          </p>
                                        </Col>
                                      </Row>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      );
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Dashboardlayout>
  );
};

export default Orders;
