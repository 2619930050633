import React, { useState, useEffect } from "react";
import Layout from "../components/Layouts/Layout";
import "../Style/howitwork.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const TermCondition = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12}>
            {/* <h5>Term and conditions</h5> */}
            <div
              name="termly-embed"
              data-id="6e7d9216-ef79-4a70-9129-73168df954e5"
              data-type="iframe"
            ></div>
            <div>
              <h1>Welcome to Listelligent</h1>

              <p>
                Listelligent allows users to locate and evaluate proposals from
                independent third-party real estate agents. Users agree to
                supply Listelligent with information about themselves and the
                property they want to sell. Listelligent will use this
                information within Listelligent and will provide certain details
                on an anonymous or non-anonymous basis depending on the
                Listelligent service to third parties who are, in most cases,
                appropriate members of its real estate agent network. Users may
                also agree to have the information he/she has supplied to
                Listelligent forwarded to real estate or other third-party
                professionals referred by Listelligent. As Listelligent uses
                email, on a regular basis, to provide Listelligent services, all
                Listelligent users must consent to receive email from
                Listelligent to register for Listelligent services. Users also
                agree to receive calls and texts from Listelligent when using
                Listelligent’s services. Listelligent users will also be
                required to consent to receive emails from third parties if the
                Listelligent service requested requires services from third
                parties (for example, a local real estate agent).
              </p>

              <p>
                Listelligent refers real estate agents to users, but
                Listelligent does not engage in actual real estate broker
                services. Real estate brokerage activities are performed by
                local real estate agents that have been referred to you by
                Listelligent. The terms of any agreement between a real estate
                agent and users are not endorsed, recommended, or otherwise
                known to or by Listelligent.
              </p>

              <p>
                Listelligent may from time to time offer eligible Users
                promotional opportunities to incentivize use of Listelligent’s
                services within a particular time frame. These opportunities may
                include but are not limited to discounts, rebates, gift
                certificates, points, or other incentives that are only
                available to Users who use a Listelligent affiliated real estate
                agent to buy or sell a home. User eligibility for a particular
                promotion will be defined in the terms of each promotion. Not
                all users may be eligible to receive all promotional
                opportunities.
              </p>

              <p>
                <strong>Acceptance of the Terms of Use</strong>
              </p>

              <p>
                These terms of use are entered into by and between You and
                Listelligent, Inc. ("Listelligent," "we," "us", or “our”). The
                following terms and conditions, together with any documents they
                expressly incorporate by reference (collectively, the "Terms of
                Use"), govern your access to and use of our website, accessible
                at https://www.listelligent.com/ (the “Site”), and our mobile
                application (the “Mobile App”) including any content,
                functionality, and services offered on or through the Site or
                Mobile App (collectively, the “Services”), whether as a guest or
                a registered user.
              </p>
              <p>
                <strong>
                  THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION
                </strong>{" "}
                AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE
                USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
                RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMITS THE
                REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
              </p>
              <p>
                Please read the Terms of Use carefully before you start to use
                the Services. By downloading and/or using the Services, you
                accept and agree to be bound and abide by these Terms of Use and
                our Privacy Policy, which can be found at{" "}
                <a
                  href="https://www.listelligent.com/term-condition"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.listelligent.com/term-condition
                </a>{" "}
                and is incorporated in these Terms of Use by reference. If you
                do not want to agree to these Terms of Use or the Privacy
                Policy, you must not access or use the Services.
              </p>
              <p>
                The Services are offered and available to users who are 18 years
                of age or older and reside in the United States or any of its
                territories or possessions. By using the Services, you represent
                and warrant that you are of legal age to form a binding contract
                with Listelligent and meet all of the foregoing eligibility
                requirements. If you do not meet all of these requirements, you
                must not access or use the Services.
              </p>
              <h2>Changes to the Terms of Use</h2>
              <p>
                We may revise and update these Terms of Use from time to time in
                our sole discretion. All changes are effective immediately when
                we post them and apply to all access to and use of the Services
                thereafter. However, any changes to the dispute resolution
                provisions set out in Governing Law and Jurisdiction will not
                apply to any disputes for which the parties have actual notice
                before the date the change is posted on the Services.
              </p>

              <h2>Equal Housing Opportunity</h2>
              <p>
                We are pledged to the letter and spirit of U.S. policy for the
                achievement of equal housing opportunity throughout the United
                States. We encourage and support an affirmative advertising and
                marketing program in which there are no barriers to obtaining
                housing because of race, color, religion, sex, handicap,
                familial status, or national origin.
              </p>

              <h2>Accessing the Services and Account Security</h2>
              <p>
                We reserve the right to withdraw or amend the Services, and any
                service or material we provide on the Services, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of the Services is unavailable at any
                time or for any period. From time to time, we may restrict
                access to some parts of the Services, or the entire Services, to
                users, including registered users.
              </p>

              <p>You are responsible for both:</p>
              <ul>
                <li>
                  Making all arrangements necessary for you to have access to
                  the Services.
                </li>
                <li>
                  Ensuring that all persons who access the Services through your
                  internet connection are aware of these Terms of Use and comply
                  with them.
                </li>
              </ul>

              <p>
                To access the Services or some of the resources it offers, you
                may be asked to provide certain registration details or other
                information. It is a condition of your use of the Services that
                all the information you provide on the Services is correct,
                current, and complete. You agree that all information you
                provide to register with this Services or otherwise, including,
                but not limited to, through the use of any interactive features
                on the Services, is governed by our Privacy Policy, and you
                consent to all actions we take with respect to your information
                consistent with our Privacy Policy.
              </p>

              <p>
                If you choose, or are provided with, a username, password, or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential, and
                you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not
                to provide any other person with access to the Services or
                portions of the Services using your username, password, or other
                security information. You agree to notify us immediately of any
                unauthorized access to or use of your username or password or
                any other breach of security. You also agree to ensure that you
                exit from your account at the end of each session. You should
                use particular caution when accessing your account from a public
                or shared computer so that others are not able to view or record
                your password or other personal information.
              </p>

              <p>
                We have the right to disable any user name, password, or other
                identifier, whether chosen by you or provided by us, at any time
                in our sole discretion for any or no reason, including if, in
                our opinion, you have violated any provision of these Terms of
                Use.
              </p>

              <p>
                You may cancel your account and membership to the Services at
                any time for any reason by delivering a written notice by email
                to{" "}
                <a href="mailto:support@listelligent.com">
                  support@listelligent.com
                </a>{" "}
                with the subject line “Membership Termination Request.”
              </p>
              <h2>User Obligations (Buyer/Seller)</h2>
              <p>
                By completing your registration with Listelligent.com as a
                potential home buyer/seller, you agree that:
              </p>
              <ul>
                <li>
                  You are of legal age and are otherwise capable of forming a
                  legally binding contract;
                </li>
                <li>
                  You are interested in buying and selling property located in
                  the United States of America;
                </li>
                <li>
                  Listelligent may request (and you agree to provide promptly),
                  receive, copy and review your real property transaction and/or
                  settlement documents and you agree to respond to requests for
                  updates involving any contact with a real estate agent
                  introduced by Listelligent;
                </li>
                <li>
                  You acknowledge that Listelligent recommends your independent
                  use of all appropriate government, public, consumer and other
                  agencies or sources to verify a real estate agent’s license
                  status and background as Listelligent.com, makes no
                  representations and/or warranties about any real estate agent
                  that may use the Listelligent service;
                </li>
                <li>
                  You do not have an exclusive contractual or other arrangement
                  with any real estate agent;
                </li>
                <li>
                  You represent that you are not licensed as a real estate agent
                  or broker;
                </li>
                <li>
                  You agree to be contacted by Listelligent via email or other
                  means as described herein, and by third parties for services
                  you have requested; and,
                </li>
                <li>
                  As a seller (if applicable), you are not bound to accept any
                  of the proposals submitted. Proposals are not binding offers
                  and are not intended to create a contractual or agency
                  relationship between you and the real estate agent.
                </li>
              </ul>

              <p>
                If required by the terms of the Listelligent service requested
                by User, you authorize Listelligent to forward your information
                to its network of real estate agents according to the terms of
                the Listelligent service that you use.
              </p>

              <p>
                Any endorsements posted by a real estate agent through the
                Listelligent customer review program are the sole responsibility
                of the real estate agents. Listelligent disclaims any liability
                arising from and the accuracy of any endorsements posted by real
                estate agents. It is your responsibility as a buyer or seller to
                evaluate the nature of any endorsement posted by any real estate
                agent and to be aware that endorsements might not reflect the
                actual quality of service that you may receive from a real
                estate agent.
              </p>

              <h2>User Obligations (Real Estate Agents)</h2>
              <p>
                Listelligent offers services targeted to real estate agents. To
                receive these services, real estate agents must accurately
                complete a registration for a desired service. Real estate
                agents agree to obtain any required broker approval prior to
                participation in any Listelligent service, and be registering
                with Listelligent, you expressly represent and warrant that all
                such permissions have been obtained. Your registration with
                Listelligent and/or use of Listelligent’s services constitutes
                your agreement to these Terms of Service.
              </p>

              <p>
                A real estate agent who signs up and submits proposals on
                Listelligent agrees to pay a referral fee as stated by the terms
                and conditions of the Listelligent Referral Agreement, available
                at:{" "}
                <a href="https://www.listelligent.com/ListelligentInc_Client_Referral_Agreement.pdf">
                  Listelligent Client Referral Agreement
                </a>
                . In the event that the agent’s broker refuses to pay the
                referral fee, the registered real estate agents will be liable
                for the entire referral fee and any collection fees.
              </p>

              <p>
                By completing your registration with the Services as a real
                estate agent, you agree that:
              </p>
              <ul>
                <li>
                  You are of legal age and are otherwise capable of forming a
                  legally binding contract; and have obtained all necessary
                  permissions within your business (if applicable) to
                  participate in the Listelligent Services;
                </li>
                <li>
                  You are an individual person (not a company or other
                  association);
                </li>
                <li>
                  All information you submit to Listelligent or pursuant to a
                  Listelligent service is accurate and complete and that you
                  will maintain and promptly update such profile, proposal(s),
                  bid(s), endorsement(s) or other information supplied to
                  Listelligent or to users to ensure accuracy at all times;
                </li>
                <li>
                  You agree to be contacted via email, phone or text by
                  Listelligent, and third parties if relevant, regarding
                  Listelligent’s services;
                </li>
                <li>
                  You have acquired and will maintain appropriate insurance
                  coverage and have complied with all real estate licensing and
                  regulatory requirements applicable to real estate agents in
                  the areas for which you have requested Listelligent service;
                </li>
                <li>
                  You will not use or attempt to use any facility provided by
                  Listelligent wholly or partly to provide you with information
                  concerning the commissions, fees, prices, or other terms
                  offered by other real estate agents;
                </li>
                <li>
                  You will not refer Listelligent supplied referrals to any
                  other person; and,
                </li>
                <li>
                  You have secured and will maintain any required supervising
                  broker’s authorization and payment of a fee that will be
                  incurred by virtue of your use of this Listelligent service,
                  if you are a licensed real estate salesperson, broker
                  associate or other non-supervising broker real estate agent
                  legally permitted to conduct or participate in certain real
                  property transactions in your state of residence or employment
                  but subject to broker oversight.
                </li>
              </ul>
              <p>
                You hereby grant Listelligent permission to email or display
                your Profile and such other information as may be supplied by
                you to Listelligent on or from the Services and such other
                partner and affiliate websites as Listelligent shall deem
                advisable in its sole determination for marketing purposes.
              </p>

              <p>
                If you choose to use the Listelligent photography service for
                your magazine-style profile, you recognize that Listelligent
                holds all right, title, and interest in and copyright rights to
                the resulting images as a work for hire under our contract with
                our photographers.
              </p>

              <p>
                Real estate agents will make the best efforts to follow up on
                all referrals generated through the Services, thus reinforcing
                Listelligent’s and its partners’ reputations. The real estate
                agent will take commercially reasonable efforts to respond to
                each referral within eight (8) hours or let Listelligent know
                within that time frame of their inability to meet this minimum
                service standard. If the real estate agent does not meet this
                service standard, Listelligent may reassign, in its sole
                discretion, any unserviced referrals to another real estate
                agent. The real estate agent may not redistribute or resell any
                Listelligent referrals without prior written permission from
                Listelligent. Real estate agents acknowledge that not all
                referrals will result in benefit to the real estate agent, and
                that ongoing cultivation and prospecting of these referrals may
                be required. Real estate agents realize that referrals received
                through the Services may be consumers who have previously used
                and/or are currently using other Internet real estate sites and
                services, including but not limited to Listelligent, to help
                them in the home selling and/or buying process.
              </p>

              <h2>Material Usage Terms</h2>
              <p>
                Your computer or mobile device may temporarily store copies of
                such materials in RAM incidental to your accessing and viewing
                those materials;
              </p>
              <ul>
                <li>
                  You may store files that are automatically cached by your Web
                  browser for display enhancement purposes;
                </li>
                <li>
                  You may print or download one copy of a reasonable number of
                  pages of the Site and one copy of the Mobile App for your own
                  personal, non-commercial use and not for further reproduction,
                  publication, or distribution;
                </li>
                <li>
                  If we provide desktop, mobile, or other applications for
                  download, you may download a single copy to your computer or
                  mobile device solely for your own personal, non-commercial
                  use, provided you agree to be bound by our end user license
                  agreement for such applications; and,
                </li>
                <li>
                  If we provide social media features with certain content, you
                  may take such actions as are enabled by such features.
                </li>
              </ul>

              <p>You must not:</p>
              <ul>
                <li>Modify copies of any materials from this Services;</li>
                <li>
                  Use any illustrations, photographs, video or audio sequences,
                  or any graphics separately from the accompanying text; or,
                </li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary
                  rights notices from copies of materials from this site.
                </li>
              </ul>
              <p>
                You must not access or use for any commercial purposes any part
                of the Services or any other services or materials available
                through the Services.
              </p>

              <p>
                If you wish to make any use of material on the Services other
                than that set out in this section, please address your request
                with the subject line “Material License Inquiry” to:
                support@listelligent.com.
              </p>

              <p>
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the Services
                in breach of the Terms of Use, your right to use the Services
                will stop immediately, and you must, at our option, return or
                destroy any copies of the materials you have made. No right,
                title, or interest in or to the Services or any content on the
                Services is transferred to you, and all rights not expressly
                granted are reserved by Listelligent. Any use of the Services
                not expressly permitted by these Terms of Use is a breach of
                these Terms of Use and may violate copyright, trademark, and
                other laws.
              </p>
              <h2>Prohibited Uses</h2>
              <p>
                You may use the Services only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use the
                Services:
              </p>

              <ul>
                <li>
                  to modify, adapt, translate, or reverse engineer any portion
                  of the Services;
                </li>
                <li>
                  in any way that violates any applicable federal, state, local,
                  or foreign law or governmental regulation;
                </li>
                <li>
                  to attempt to gain unauthorized access to any accounts,
                  features, systems, or networks through hacking;
                </li>
                <li>
                  for the purpose of exploiting, harming, or attempting to
                  exploit or harm minors;
                </li>
                <li>
                  to transmit any advertising or promotional material without
                  our prior written consent;
                </li>
                <li>
                  to impersonate Listelligent, an Listelligent employee, another
                  user, or any other person or entity;
                </li>
              </ul>

              <p>Additionally, you agree not to:</p>

              <ul>
                <li>
                  use the Services in any manner that could disable, overburden,
                  damage, or impair the Services;
                </li>
                <li>
                  use any automated device or means to interfere with the proper
                  working of the Services;
                </li>
                <li>
                  introduce or transmit any viruses, Trojan horses, or other
                  material that is malicious or harmful;
                </li>
                <li>
                  attempt to gain unauthorized access to, interfere with, or
                  disrupt any parts of the Services;
                </li>
                <li>
                  circumvent or attempt to circumvent any security or
                  authentication measures;
                </li>
                <li>
                  violate, infringe, or misappropriate the intellectual property
                  or other proprietary rights of Listelligent.
                </li>
              </ul>

              <p>
                We reserve the right to prohibit access, use, conduct,
                communications, or content that we, in our sole discretion, deem
                to be harmful to the Services, users, Listelligent, our brand,
                or any other person or entity, or that violates these Terms of
                Use and/or applicable laws, rules, or governmental regulations.
              </p>
              <h2>For Agent and User Terms</h2>
              <ul>
                <li>Leads are not guaranteed</li>
                <li>Plans are month-to-month with no contract</li>
                <li>
                  All agents must be licensed and current with their state and
                  associations
                </li>
                <li>No refunds on 1-year purchases</li>
                <li>We reserve the right to cancel agent subscriptions</li>
              </ul>
              <h2>User Contributions</h2>
              <p>
                The Services may contain Interactive Services that allow users
                to Post content or materials (User Contributions). All User
                Contributions must comply with the Content Standards set out in
                these Terms of Use.
              </p>

              <p>
                Any User Contribution you Post to the Services will be
                considered non-confidential and non-proprietary and may become
                public. You should not include any information in your User
                Contribution that you do not want other parties to see or use.
              </p>
              <p>
                By providing any User Contribution on or through the Services,
                you grant us and our affiliates and service providers the right
                to use, reproduce, modify, disclose, and distribute your User
                Contribution for any and all commercial or non-commercial
                purposes without compensation to you or anyone else.
              </p>

              <p>You represent and warrant that:</p>

              <ul>
                <li>
                  You own or control all rights in and to the User
                  Contributions;
                </li>
                <li>
                  Your User Contributions are accurate, current, and complete;
                </li>
                <li>
                  Your User Contributions do not infringe or misappropriate any
                  third-party rights;
                </li>
                <li>
                  Your User Contributions and your use of the Services comply
                  with applicable laws;
                </li>
              </ul>

              <p>
                User Contributions may also include reviews or testimonials
                about the Services, Listelligent, and/or its products and
                services. You agree that any statements you make in any User
                Contribution accurately reflect your personal beliefs and
                experiences and that any opinions submitted are true to the best
                of your knowledge.
              </p>

              <p>
                You understand and acknowledge that you are responsible for any
                User Contributions you submit, and you, not Listelligent, have
                full responsibility for such content, including its legality,
                reliability, accuracy, and appropriateness.
              </p>

              <p>
                We are not responsible or liable to any third party for the
                content or accuracy of any User Contributions Posted by you or
                any other user of the Services.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default TermCondition;
