import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { useNavigate, useParams, Link } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../../Style/custom.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/Loader";
import ReviewImg from "../../assets/review_img.png";
import { FaPlayCircle } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import { Modal } from "react-bootstrap";

const AgentProfile = () => {
  const { id } = useParams();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);

  const getUserProfile = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent/profile/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      setUserData(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const getAgentProfileData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent/profile-data/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setProfileData(response.data.data);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
      setProfileData(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {
    getUserProfile(id);
    getAgentProfileData(id);
  }, [id]);

  const handlePhoneCall = () => {
    const telUrl = `tel:${profileData.phone}`;
    window.open(telUrl);
  };

  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <div className="myprofile-conteiner">
        <Container>
          <NotificationContainer />
          <Row>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <div className="myprofile-angent-img-icons">
                    {profileData.profile ? (
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${profileData.profile}`}
                      />
                    ) : (
                      <img
                        alt="profile-img"
                        src={require("../../assets/no-image.png")}
                      />
                    )}

                    <div className="d-flex justify-content-center mt-3 myprofile-social-icons">
                      {profileData.review && (
                        <a
                          href={profileData.review}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            title="Customer review"
                            src={ReviewImg}
                            alt="Review"
                            height={30}
                            style={{ width: "30px" }}
                          />
                        </a>
                      )}
                      {profileData.facebook && (
                        <a
                          href={profileData.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFacebook className="facebook" />
                        </a>
                      )}

                      {profileData.linkedin && (
                        <a
                          href={profileData.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaLinkedin className="linkedin" />
                        </a>
                      )}

                      {profileData.twitter && (
                        <a
                          href={profileData.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaTwitter className="twitter" />
                        </a>
                      )}

                      {profileData.youtube && (
                        <a
                          href={profileData.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaYoutube className="youtube" />
                        </a>
                      )}
                      {profileData.website && (
                        <a
                          href={
                            profileData.website?.startsWith("http")
                              ? profileData.website
                              : `http://${profileData.website}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaGlobe />
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={9} className="myprofile-content">
                  <h2>{userData.name}</h2>
                  <p>{userData.brokerage}</p>
                  <p>
                    <span>Email</span>
                    {userData.email}
                  </p>
                  {profileData.website !== "" &&
                    profileData.website !== null && (
                      <p>
                        <span>Website</span>
                        <a
                          href={
                            profileData.website?.startsWith("http")
                              ? profileData.website
                              : `http://${profileData.website}`
                          }
                          target="_blank"
                        >
                          {profileData.website}
                        </a>
                      </p>
                    )}
                  <p>
                    <span>Address</span>
                    {userData.office_address}
                  </p>
                  <p>
                    <span>Agent License</span>
                    {userData.license}
                  </p>
                  <p>
                    <span>Speaks</span>
                    {profileData.language}
                  </p>
                  <p>
                    <span>Phone</span>
                    {profileData.phone ? (
                      <>
                        {profileData.phone}{" "}
                        <Button
                          className="find-btn"
                          size="sm"
                          onClick={handlePhoneCall}
                        >
                          <MdCall size={15} />
                        </Button>
                      </>
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div className="myprofile-video-content">
                {extractVideoId(profileData.video_url) ? (
                  <>
                    <iframe
                      style={{ borderRadius: "10px" }}
                      width="100%"
                      height="350"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        profileData.video_url
                      )}`}
                      title="Welcome to Listelligent"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                ) : (
                  <>
                    {profileData.profile ? (
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${profileData.profile}`}
                      />
                    ) : (
                      <img
                        alt="profile-img"
                        src={require("../../assets/no-image.png")}
                      />
                    )}
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                )}
                <MyVerticallyCenteredModal
                  show={modalShow}
                  profileData={profileData}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </Col>
          </Row>

          <div className="myprofile-agent-about-info">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="myprofile-agent-about-info-heading">
                    <h3>More About {userData.name}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileData.description,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Row className="myprofile-sub-img-content">
            <Col md={12}>
              <h4>Featured Content</h4>
              <Row className="myprofile-sub-img">
                {profileData?.promotionalImgs &&
                profileData.promotionalImgs.length !== 0 ? (
                  profileData.promotionalImgs.map((image, index) => (
                    <Col key={index} md={4} className="p-2 text-center mb-3">
                      <div className="image-container">
                        <img
                          alt=""
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/agent_images/${image.image}`}
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <p>No promotional images available.</p>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Dashboardlayout>
  );
};

function extractVideoId(youtubeUrl) {
  try {
    const url = new URL(youtubeUrl);
    const pathname = url.pathname.slice(1);
    const videoId = url.searchParams.get("v") || pathname;
    return videoId;
  } catch (error) {
    // console.error("Invalid YouTube URL:", youtubeUrl);
    return null;
  }
}

function MyVerticallyCenteredModal(props) {
  const videoId = extractVideoId(props.profileData.video_url);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {videoId ? (
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="Welcome to Listelligent"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ) : (
          <h4>No valid YouTube video found...</h4>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default AgentProfile;
