import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { CiDiscount1 } from "react-icons/ci";
import { FiDownload } from "react-icons/fi";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import TimeAgo from "react-timeago";

const ViewOrder = () => {
  const { id } = useParams();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [prevId, setPrevId] = useState(null);
  useEffect(() => {}, [data]);

  const getOrderData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/order/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        setData(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    if (id !== prevId) {
      getOrderData();
      setPrevId(id);
    }
  }, [id]);

  const handleDownloadClick = async (id, user_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/generateInvoice/${id}/${user_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_BASE_URL}${response.data.filepath}`;
        link.download = `invoice_${id}.pdf`;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <Dashboardlayout>
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={11}>
                    <h4>View order</h4>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Agent name</th>
                      <td>{data.user?.name}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{data.user?.email}</td>
                    </tr>
                    <tr>
                      <th>Promo code</th>
                      <td>
                        <b>{data.promo_code ? data.promo_code : "N/A"}</b>
                      </td>
                    </tr>
                    <tr>
                      <th>Discount</th>
                      <td>
                        <span className="status-tag mb-3" title="Discount">
                          <CiDiscount1 size={20} />{" "}
                          {data.discount ? data.discount : 0}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Total</th>
                      <td>${data.total}</td>
                    </tr>
                    <tr>
                      <th>Transaction ID</th>
                      <td>{data.transaction_id}</td>
                    </tr>
                    <tr>
                      <th>Placed at</th>
                      <td>
                        <span className="status-tag mb-3">
                          {" "}
                          <TimeAgo date={data.created_at} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice</th>
                      <td>
                        <span
                          className="status-tag mb-3"
                          title="Download invoice"
                          onClick={() =>
                            handleDownloadClick(data.id, data.user?.id)
                          }
                        >
                          <FiDownload size={20} />
                        </span>
                      </td>
                    </tr>                               
                  </tbody>
                </Table>
                <h5 className="mt-4">Ordered List of Zip Codes</h5>
                <hr/>
                <Table>
                  <thead>
                    <th>Sr</th>
                    <th>City</th>
                    <th>Zip code</th>
                    <th>Price</th>
                  </thead>
                  <tbody>
                    {data.orderProduct?.map((product, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{product.city}</td>
                        <td>{product.zip_code}</td>
                        <td>{product.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Dashboardlayout>
  );
};

export default ViewOrder;
