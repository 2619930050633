import React, { useState, useEffect } from "react";
import Layout from "../components/Layouts/Layout";
import "../Style/agentprofile.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LuBedDouble } from "react-icons/lu";
import { LuBath } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";
import dummyAgent from "../assets/agreement.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MdCall } from "react-icons/md";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReviewImg from "../assets/review_img.png";
import Loader from "../components/Loader";
import { Select } from "@mui/material";

const Agentprofile = () => {
  const { id } = useParams();
  const [modalShow, setModalShow] = React.useState(false);
  const [contactShow, setContactShow] = React.useState(false);
  const [ordersList, setOrderlist] = useState([]);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const [agentData, setAgentData] = useState([]);
  const [videoId, setVideoId] = useState("");
  console.log(id);
  useEffect(() => {
    getAgentData(id);
    fetchData(id);
  }, [id]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    zip_code: "",
    agent_zip_code: "",
    state: "",
    select: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "agent_zip_code") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        zip_code: value,
      }));
    }
  };
  const getAgentData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/view/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        setAgentData(response.data.data);
        const extractedVideoId = extractVideoId(
          response.data.data.agentData.video_url
        );
        setVideoId(extractedVideoId);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      // NotificationManager.error("Error", "Something went to wrong", 3000);
    }
  };
  const handlePhoneCall = () => {
    if (agentData && agentData.agentData && agentData.agentData.phone) {
      const telUrl = `tel:${agentData.agentData.phone}`;
      window.open(telUrl);
    }
  };
  function extractVideoId(youtubeUrl) {
    try {
      const url = new URL(youtubeUrl);
      const pathname = url.pathname.slice(1);
      const videoId = url.searchParams.get("v") || pathname;
      return videoId;
    } catch (error) {
      console.error("Invalid YouTube URL:", youtubeUrl);
      return null;
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsOpen((isOpen) => !isOpen);
    // setisSubmit(true);
    addUserLead();
    // addUserLead();
  };
  const handleContact = () => {
    setContactShow(true);
    // fetchData();
  };

  const addUserLead = async () => {
    console.log(formData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addLead`,
        formData,
        {
          headers: {
            Authorization: `Bearer 455454545ddsdksdjksdjksdsjdksjdksj`,
          },
          withCredentials: true,
        }
      );
      if (response.status) {
        localStorage.setItem("formData", JSON.stringify(formData));
        NotificationManager.success(
          "Lead send successfully ",
          response.data.message,
          3000
        );
        setContactShow(false);
        setFormData({
          name: "",
          phone: "",
          email: "",
          address: "",
          zip_code: "",
          agent_zip_code: "",
          state: "",
          select: "",
        });
        // setIsOpen(true)
        // setIsOpen((isOpen) => !isOpen);
        // navigate("/agentslist");
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };
  useEffect(() => {}, [location.pathname]);
  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/order/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        // console.log(response);
        setOrderlist(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      // setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  const handleCloseContact = () => {
    setContactShow(false);
    setFormData({
      name: "",
      phone: "",
      email: "",
      address: "",
      zip_code: "",
      agent_zip_code: "",
      state: "",
      select: "",
    });
  };
  // console.log(ordersList);
  return (
    <Layout>
      <div className="profile-conteiner">
        <Container>
          <NotificationContainer />
          <Row>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <div className="angent-img-icons">
                    {agentData.agentData &&
                    agentData.agentData.profile !== "" ? (
                      <img
                        alt="agent"
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${agentData.agentData.profile}`}
                      ></img>
                    ) : (
                      <img alt="agent" src={dummyAgent} />
                    )}
                    <div className="d-flex justify-content-center mt-3 social-icons">
                      {agentData.agentData && (
                        <>
                          {agentData.agentData.review && (
                            <a
                              href={agentData.agentData.review}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                title="Customer review"
                                src={ReviewImg}
                                alt="Review"
                                height={30}
                                style={{ width: "30px" }}
                              />
                            </a>
                          )}
                          {agentData.agentData.facebook && (
                            <a
                              href={agentData.agentData.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaFacebook className="facebook" />
                            </a>
                          )}

                          {agentData.agentData.linkedin && (
                            <a
                              href={agentData.agentData.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaLinkedin className="linkedin" />
                            </a>
                          )}

                          {agentData.agentData.twitter && (
                            <a
                              href={agentData.agentData.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaTwitter className="twitter" />
                            </a>
                          )}

                          {agentData.agentData.youtube && (
                            <a
                              href={agentData.agentData.youtube}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaYoutube className="youtube" />
                            </a>
                          )}
                          {agentData.agentData.website && (
                            <a
                              href={
                                agentData.agentData.website?.startsWith("http")
                                  ? agentData.agentData.website
                                  : `http://${agentData.agentData.website}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaGlobe />
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={9} className="profile-content">
                  <h2 style={{textAlign:"left"}}>{agentData.name}</h2>
                  <p>{agentData.brokerage}</p>
                  <p>
                    <span>Email</span>
                    {agentData.email}
                  </p>
                  {agentData.agentData?.website && (
                    <p>
                      <span>Website</span>
                      <a
                        href={
                          agentData.agentData.website?.startsWith("http")
                            ? agentData.agentData.website
                            : `http://${agentData.agentData.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {agentData.agentData.website}
                      </a>
                    </p>
                  )}
                  <p>
                    <span>Address</span>
                    {agentData.office_address}
                  </p>
                  <p>
                    <span>Agent License</span>
                    {agentData.license}
                  </p>
                  <p>
                    <span>Speaks</span>
                    {agentData.agentData?.language}
                  </p>
                  <p>
                    {/* <span>Phone</span>
                    {agentData.agentData?.phone}{" "}
                    <Button
                      className="find-btn"
                      size="sm"
                      onClick={handlePhoneCall}
                    >
                      <MdCall size={15} />
                    </Button> */}
                    <span>Phone</span>
                    {agentData.agentData?.phone ? (
                      <>
                        {agentData.agentData.phone}{" "}
                        <Button
                          className="find-btn"
                          size="sm"
                          onClick={handlePhoneCall}
                        >
                          <MdCall size={15} />
                        </Button>
                      </>
                    ) : (
                      <span>No phone number available</span>
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div className="video-content">
                {/* {agentData.agentData && agentData.agentData.profile !== "" ? (
                  <img
                    alt="agent"
                    src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${agentData.agentData.profile}`}
                  ></img>
                ) : (
                  <img src="https://video-cdn.ziggeo.com/v1/applications/e0601e02e994ce8d4763a3cff2190b09/videos/691b73e73d4c589acfd1870b1f2afad0/image"></img>
                )}
                <Link onClick={() => setModalShow(true)}>
                  <FaPlayCircle />
                </Link> */}
                {agentData.agentData &&
                extractVideoId(agentData.agentData.video_url) ? (
                  <>
                    <iframe
                      style={{ borderRadius: "10px" }}
                      width="100%"
                      height="350"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        agentData.agentData.video_url
                      )}`}
                      title="Welcome to Listelligent"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                ) : (
                  <>
                    {agentData.agentData && agentData.agentData.profile ? (
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${agentData.agentData.profile}`}
                      />
                    ) : (
                      <img
                        alt="profile-img"
                        src={require("../assets/no-image.png")}
                      />
                    )}
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                )}
                <Modal
                  show={modalShow}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => setModalShow(false)}
                >
                  <Modal.Body>
                    {videoId ? (
                      <iframe
                        width="100%"
                        height="400"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="Welcome to Listelligent"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <h4>No YouTube video found...</h4>
                    )}
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          </Row>
          <div className="agent-about-info">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="agent-about-info-heading">
                    <h3>More About {agentData.name}</h3>
                    <div>
                      {agentData.agentData &&
                      agentData.agentData.description !== "" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: agentData.agentData.description,
                          }}
                        />
                      ) : (
                        <p>No content found.</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Row className="myprofile-sub-img-content">
            <Col md={12}>
              <h4>Featured Content</h4>
              <Row className="myprofile-sub-img">
                {agentData.agentData?.promotionalImgs &&
                agentData.agentData.promotionalImgs.length !== 0 ? (
                  agentData.agentData.promotionalImgs.map((image, index) => (
                    <Col key={index} md={4} className="p-2 text-center mb-3">
                      {/* <img
                        alt=""
                        width={250}
                        height={300}
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_images/${image.image}`}
                      /> */}
                      <div className="image-container">
                        <img
                          alt=""
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/agent_images/${image.image}`}
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <p>No promotional images available.</p>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="text-center" style={{ background: "#f7faff" }}>
          <Button
            className="contact_btn btn btn-primary w-auto"
            onClick={() => handleContact(id)}
          >
            Contact {agentData.name}
          </Button>
          <Modal
            show={contactShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleCloseContact}
          >
            <Modal.Body>
              {ordersList.length > 0 ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Control
                    className="mt-4 mb-4"
                    name="name"
                    size="lg"
                    type="text"
                    placeholder="First and Last Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-4 mb-4"
                    name="phone"
                    size="lg"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  {/* <div className='or'><span>OR</span></div> */}
                  <Form.Control
                    className="mt-1 mb-4"
                    name="email"
                    size="lg"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Form.Select
                    value={formData.Select}
                    className="mt-1 mb-4"
                    onChange={(e) => {
                      const index = e.target.value;
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        zip_code: ordersList[index].orderProduct[0]?.zip_code,
                      }));
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        agent_zip_code: ordersList[index].orderProduct[0]?.zip_code,
                      }));
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        state: ordersList[index].orderProduct[0]?.state,
                      }));
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        address: ordersList[index].orderProduct[0]?.city,
                      }));
                    }}
                    size="lg"
                    aria-label="Select Address"
                  >
                    <option value="">Open this select menu</option>

                    {ordersList.map((provider, index) => (
                      <option value={index}>
                        {provider.orderProduct[0]?.city},
                        {provider.orderProduct[0]?.state}{" "}
                        {provider.orderProduct[0]?.zip_code}
                      </option>
                    ))}
                  </Form.Select>

                  <div className="d-flex text-center justify-center">
                  <Button type="submit mb-4 " className="contact_btn " variant="">
                    Continue
                  </Button>{" "}
                  </div>
                  
                </Form>
              ) : (
                <Loader></Loader>
              )}
            </Modal.Body>
           
          </Modal>
        </Container>
      </div>

      {/* <div className="slider">
        <Container>
          <Row>
            <Col md={12}>
              <div className="container-fluid">
                <h1>Featured Transactions</h1>
                <OwlCarousel
                  items={3}
                  className="owl-theme"
                  loop
                  nav
                  margin={8}
                  responsive={{
                    0: { items: 1 },
                    768: { items: 2 },
                    1224: { items: 3 },
                  }}
                >
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279583_880486ef-2021-46fb-a595-559c83272bb1.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Sold</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279588_513b5826-e5a9-4ce1-9fd0-0efca3b158b0.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Sold</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279585_4cbaa342-8d16-4eee-bfb7-0db320359147.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Bought</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279581_698da2e1-bfeb-46c2-aac0-f4a099cee868.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Bought</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279583_880486ef-2021-46fb-a595-559c83272bb1.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Sold</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279588_513b5826-e5a9-4ce1-9fd0-0efca3b158b0.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Bought</p>
                  </div>
                  <div className="carousel-card">
                    <img
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279585_4cbaa342-8d16-4eee-bfb7-0db320359147.jpg"
                      }
                    />
                    <div className="carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="tag">Sold</p>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </Layout>
  );
};

export default Agentprofile;
