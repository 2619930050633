import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { FaPlus, FaCloudUploadAlt, FaSync } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import usStates from "../usStates";
import Loader from "../../components/Loader";

const CommunitiesDistrict = () => {
  const { state_id } = useParams();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contentModal, setContentModal] = useState(false);
  const [contentData, setContentData] = useState([]);
  const [stateInfo, setStateInfo] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const [fileError, setFileError] = useState("");

  const [values, setValues] = useState({
    name: "",
    district_img: "",
  });

  const handelInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setValues((prev) => ({
      ...prev,
      district_img: file,
    }));
  };

  const handelSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("district_img", values.district_img);
    formData.append("state_id", stateInfo.id);
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/district/add`,
        formData,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setModalShow(false);
        NotificationManager.success("State", response.data.message, 1500);
        getStateDistricts();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      setFileError(error.response.data.error);
    }
  };

  const viewContent = async (id) => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}admin/district/view-content/${id}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
        withCredentials: true,
      }
    );
    setLoading(false);
    if (response.status) {
      setContentData(response.data.data);
      setContentModal(true);
    } else {
      NotificationManager.error("Error", response.data.message, 3000);
    }
  };

  const generateNewContent = async (id) => {
    setLoading(true);
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/district/new-generate/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        getStateDistricts();
        NotificationManager.success("District", response.data.message, 1500);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      setFileError(error.response.data.error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  const uploadCsvFile = async (e) => {
    e.preventDefault();
    const file = csvFile;
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("csvFile", file);
        formData.append("state_id", stateInfo.id);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/csv/district/add`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
        setLoading(false);
        if (response.status) {
          setFileModal(false);
          setCsvFile(null);
          NotificationManager.success("State", response.data.message, 1500);
          getStateDistricts();
        } else {
          NotificationManager.error("Error", response.message, 3000);
        }
      } catch (error) {
        setLoading(false);
        setFileError(error.response.data.error);
      }
    } else {
      NotificationManager.error("Error", "Please select csv file", 3000);
    }
  };

  const emptyContentGenerate = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/district/empty-generate/${stateInfo.id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        getStateDistricts();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      setFileError(error.response.data.error);
    }
  };

  const [data, setData] = useState([]);
  const [records, setRecords] = useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  function handlefilter(event) {
    const searchQuery = event.target.value.toLowerCase();

    const newData = data.filter((row) => {
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const value = row[key];
          const valueString =
            typeof value === "string" || typeof value === "number"
              ? value.toString().toLowerCase()
              : "";
          if (valueString.includes(searchQuery)) {
            return true;
          }
        }
      }
      return false;
    });

    setRecords(newData);
  }
  const getStateDistricts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/district/${state_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setData(response.data.data.district);
        setStateInfo(response.data.data.stateInfo);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    getStateDistricts();
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "District name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Content",
      cell: (row) => (
        <span>
          {row.content ? (
            <b className="text-success">Yes</b>
          ) : (
            <b className="text-danger">No</b>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <Link to={`/admin/communities/${state_id}/${row.id}`}>
            <Button
              title="View District"
              className="m-1"
              variant="warning"
              size="sm"
            >
              <b>View Cities</b>
            </Button>
          </Link>
          <Button
            title="View Content"
            className="m-1"
            variant="warning"
            size="sm"
            onClick={() => viewContent(row.id)}
          >
            <b>View</b>
          </Button>
          <Button
            title="Generate Content"
            className="m-1"
            variant="secondary"
            size="sm"
            onClick={() => generateNewContent(row.id)}
          >
            <FaSync />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={6}>
                    <h4>District Communities</h4>
                    <Link to={"/admin/state-communities"}>
                      {stateInfo.name}
                    </Link>{" "}
                    / District
                  </Col>
                  <Col md={6} className="text-end">
                    <Button
                      title="Add District code"
                      size="sm"
                      variant="success"
                      onClick={() => setModalShow(true)}
                    >
                      <FaPlus />
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      variant="warning"
                      title="Upload csv file to add zip code"
                      onClick={() => setFileModal(true)}
                    >
                      <FaCloudUploadAlt />
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      variant="info"
                      title="Empty content Generate"
                      onClick={() => emptyContentGenerate()}
                    >
                      Empty Content Generate
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="datatable">
                  <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      onChange={handlefilter}
                    />
                  </div>
                  {fileError !== "" && (
                    <Alert
                      variant="danger"
                      onClose={() => setFileError("")}
                      dismissible
                    >
                      <p>{fileError}</p>
                    </Alert>
                  )}
                  <DataTable
                    columns={columns}
                    data={records}
                    // selectableRows
                    pagination
                    highlightOnHover
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Add District model */}
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Add District</h3>
            <Form onSubmit={handelSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  value={stateInfo.name}
                  className="shadow-none"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>District Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  placeholder="Enter District name"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end add District modal */}
        {/* View content */}
        <Modal
          show={contentModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">
              View {contentData?.name} District Content
            </h3>
            <hr />
            <div className="p-1 text-center">
              {contentData.img && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}assets/district/${contentData.img}`}
                  className="mb-2"
                  alt=""
                />
              )}
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: contentData.content
                    ? contentData.content
                    : "No content found.",
                }}
              ></div>
            </div>
            <div className="zip-submit-btn d-flex justify-content-end">
              <span
                className="btn btn-danger"
                onClick={() => setContentModal(false)}
              >
                Close
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* View content */}
        {/* Add File upload model */}
        <Modal
          show={fileModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Upload District csv file</h3>
            <Form onSubmit={uploadCsvFile}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  className="shadow-none"
                  value={stateInfo.name}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select csv file</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  required
                  accept=".csv"
                  onChange={handleFileInputChange}
                />
              </Form.Group>
              <a
                href={`${process.env.REACT_APP_BASE_URL}assets/demo-csv/district.csv`}
                download="state.csv"
              >
                Sample csv file
              </a>
              <Alert variant="warning">
                Please do not refresh or close the window while the file is
                uploading. This may take some time, especially for large files.
                Additionally, records already existing in the database will be
                skipped during the upload process.
              </Alert>
              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setFileModal(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end File upload modal */}
      </Container>
    </Dashboardlayout>
  );
};

export default CommunitiesDistrict;
