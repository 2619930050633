import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Nopage from "./Pages/Nopage";
import Singlepost from "./Pages/Singlepost";
import Howitwork from "./Pages/Howitwork";
import Agentslist from "./Pages/Agentslist";
import Agentprofile from "./Pages/Agentprofile";
import Agentsignup from "./Pages/Agents/Agentsignup";
import Howagentswork from "./Pages/Agents/Howagentswork";
import Myprofile from "./Pages/Agents/Myprofile";
import Dashboard from "./Pages/Admin/Dashboard";
import Icons from "./Pages/Admin/Icons";
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Agentsignupform from "./Pages/Agents/Agentsignupform";
import Protectedrout from "./Pages/Admin/Protectedrout";
import Agentsview from "./Pages/Admin/Agentsview";
import Agentdashboard from "./Pages/Agents/Agentdashboard";
import Myproposals from "./Pages/Agents/Myproposals";
import agentLeads from "./Pages/Agents/agentLeads";
import Agentperformance from "./Pages/Agents/Agentperformance";
import Agentaccount from "./Pages/Agents/Agentaccount";
import AgentOrders from "./Pages/Agents/AgentOrders";
import Agentsfaq from "./Pages/Agents/Agentsfaq";
// import AgentProtectedR from "./Pages/Agents/AgentProtectedR";
import ZipCode from "./Pages/Admin/ZipCode";
import { AuthProvider } from "./AuthContext";
import PurchaseZip from "./Pages/Agents/PurchaseZip";
import Orders from "./Pages/Admin/Orders";
import Leads from "./Pages/Admin/Leads";
import Profile from "./Pages/Admin/Profile";
import PromoCode from "./Pages/Admin/PromoCode";
import FAQs from "./Pages/Admin/FAQs";
import FAQsAgent from "./Pages/Admin/FAQsAgent";
import EmailTemplate from "./Pages/Admin/EmailTemplate";
import ViewOrder from "./Pages/Admin/ViewOrder";
import ContactUs from "./Pages/Admin/ContactUs";
import GoogleAds from "./Pages/Admin/GoogleAds";

import TermCondition from "./Pages/TermCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Disclaimer from "./Pages/Disclaimer";
import Cookie from "./Pages/Cookie";
import AgentZipsView from "./Pages/Admin/AgentZipsView";
import AgentProfile from "./Pages/Admin/AgentProfile";
import CommunitiesState from "./Pages/Admin/CommunitiesState";
import CommunitiesDistrict from "./Pages/Admin/CommunitiesDistrict";
import CommunitiesCity from "./Pages/Admin/CommunitiesCity";
import StatePage from "./Pages/StatePage";
import DistrictPage from "./Pages/DistrictPage";
import CityPage from "./Pages/CityPage";
import StateList from "./Pages/StateList";
import GoogleAuth from "./Pages/Admin/googleauth";
import GoogleSetting from "./Pages/Admin/GoogleSetting";
import Pricing from "./Pages/Admin/Pricing";

function App() {
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="singlepost" element={<Singlepost />} />
            <Route path="how-it-works" element={<Howitwork />} />
            <Route path="agentslist" element={<Agentslist />} />
            <Route path="state" element={<StateList />} />
            <Route path="state/:state" element={<StatePage />} />
            <Route path="state/:state/:district" element={<DistrictPage />} />
            <Route path="state/:state/:district/:city" element={<CityPage />} />
            <Route path="agentprofile/:id" element={<Agentprofile />} />
            <Route path="agentsignup" element={<Agentsignup />} />
            <Route path="agentsignupform" element={<Agentsignupform />} />
            <Route path="term-condition" element={<TermCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="cookie-policy" element={<Cookie />} />
            <Route path="howagentswork" element={<Howagentswork />} />

            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="signup" element={<Signup />} />

            <Route
              path="admin/dashboard"
              element={<Protectedrout Component={Dashboard} />}
            />
            <Route
              path="admin/orders"
              element={<Protectedrout Component={Orders} />}
            />
             <Route
              path="admin/orders/:order_id"
              element={<Protectedrout Component={Orders} />}
            />
            <Route
              path="admin/leads"
              element={<Protectedrout Component={Leads} />}
            />
            <Route
              path="admin/agentsview"
              element={<Protectedrout Component={Agentsview} />}
            />
            <Route
              path="admin/zipcode"
              element={<Protectedrout Component={ZipCode} />}
            />
            <Route
              path="admin/state-communities"
              element={<Protectedrout Component={CommunitiesState} />}
            />
            <Route
              path="admin/communities/:state_id"
              element={<Protectedrout Component={CommunitiesDistrict} />}
            />

            <Route
              path="admin/communities/:state_id/:district_id"
              element={<Protectedrout Component={CommunitiesCity} />}
            />

            <Route
              path="admin/profile"
              element={<Protectedrout Component={Profile} />}
            />

            <Route
              path="admin/promo-code"
              element={<Protectedrout Component={PromoCode} />}
            />
            <Route
              path="admin/faqs"
              element={<Protectedrout Component={FAQs} />}
            />
            <Route
              path="admin/agent-faqs"
              element={<Protectedrout Component={FAQsAgent} />}
            />
            <Route
              path="admin/email-template"
              element={<Protectedrout Component={EmailTemplate} />}
            />
            <Route
              path="admin/contact-us"
              element={<Protectedrout Component={ContactUs} />}
            />
            <Route
              path="admin/agent/view/:id"
              element={<Protectedrout Component={AgentZipsView} />}
            />
            <Route
              path="admin/agent/profile/:id"
              element={<Protectedrout Component={AgentProfile} />}
            />
            <Route path="admin/order/:id" element={<ViewOrder />} />
            <Route
              path="agentDashboard"
              element={<Protectedrout Component={Agentdashboard} />}
            />
            <Route
              path="myProposal"
              element={<Protectedrout Component={Myproposals} />}
            />
            <Route
              path="leads"
              element={<Protectedrout Component={agentLeads} />}
            />
            <Route
              path="agentPerformance"
              element={<Protectedrout Component={Agentperformance} />}
            />
            <Route
              path="myAgentProfile"
              element={<Protectedrout Component={Myprofile} />}
            />
            <Route
              path="agentsAccount"
              element={<Protectedrout Component={Agentaccount} />}
            />
            <Route
              path="agent/orders"
              element={<Protectedrout Component={AgentOrders} />}
            />
            <Route
              path="agentsFAQ"
              element={<Protectedrout Component={Agentsfaq} />}
            />

            <Route
              path="agent/purchase-zip"
              element={<Protectedrout Component={PurchaseZip} />}
            />
                <Route
              path="admin/pricing"
              element={<Protectedrout Component={Pricing} />}
            />
             <Route
              path="admin/ads"
              element={<GoogleSetting />}
            />
             <Route
              path="admin/adslist"
              element={<GoogleAds />}
            />
            <Route
              path="callback"
              element={<GoogleAuth />}
            />
            <Route path="*" element={<Nopage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
