import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import Form from "react-bootstrap/Form";
import { Link, useNavigate,useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Button from "react-bootstrap/Button";

const GoogleAuth = () => {
  const authToken = localStorage.getItem("token");
  
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get('code');
    
//   console.log(routes);
  useEffect(() => {
   
    // console.log(authCode);
    if (authCode != "") {
      handleSubmit(authCode);
    }
  }, []);
  const handleSubmit = async (code) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}callback`,
        {
          code: code,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log(response);

      if(response.data.refresh_token){
        navigate(`/admin/ads?refresh_token=${response.data.refresh_token}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }

    // Handle the form data as needed, e.g., send to an API endpoint
  };
  return (
    <Dashboardlayout>
      <Container fluid>
        {/* <NotificationContainer /> */}
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <h4>Authorization code : {authCode}</h4>
                <br></br>
                <br></br>
                <h4 className="text-center">Please wait Generating refresh_token.....</h4>
              </Card.Header>
            </Card>
          </Col>
        </Row>
      
      </Container>
   </Dashboardlayout>
  );
};

export default GoogleAuth;
