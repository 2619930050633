import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { FaPlus, FaCloudUploadAlt, FaSync } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import Loader from "../../components/Loader";
import Alert from "react-bootstrap/Alert";

const CommunitiesCity = () => {
  const { state_id, district_id } = useParams();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState([]);
  const [districtInfo, setDistrictInfo] = useState([]);
  const [contentModal, setContentModal] = useState(false);
  const [contentData, setContentData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const [fileError, setFileError] = useState("");

  const [values, setValues] = useState({
    name: "",
    district_id: "",
    city_img: "",
  });

  const handelInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setValues((prev) => ({
      ...prev,
      city_img: file,
    }));
  };

  const handelSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("city_img", values.city_img);
    formData.append("district_id", districtInfo.id);
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/city/add`,
        formData,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setModalShow(false);
        NotificationManager.success("State", response.data.message, 1500);
        getDistrictsCity();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const viewContent = async (id) => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}admin/city/view-content/${id}`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
        withCredentials: true,
      }
    );
    setLoading(false);
    if (response.status) {
      setContentData(response.data.data);
      setContentModal(true);
    } else {
      NotificationManager.error("Error", response.data.message, 3000);
    }
  };

  const generateNewContent = async (id) => {
    setLoading(true);
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/city/new-generate/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success("State", response.data.message, 1500);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      setFileError(error.response.data.error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  const uploadCsvFile = async (e) => {
    e.preventDefault();
    const file = csvFile;
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("csvFile", file);
        formData.append("state_id", stateInfo.id);
        formData.append("district_id", districtInfo.id);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/csv/city/add`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
        setLoading(false);
        if (response.status) {
          setFileModal(false);
          setCsvFile(null);
          NotificationManager.success("State", response.data.message, 1500);
          getDistrictsCity();
        } else {
          NotificationManager.error("Error", response.message, 3000);
        }
      } catch (error) {
        setLoading(false);
        setFileError(error.response.data.error);
      }
    } else {
      NotificationManager.error("Error", "Please select csv file", 3000);
    }
  };

  const getDistrictsCity = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/cities/${state_id}/${district_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setData(response.data.data.cities);
        setDistrictInfo(response.data.data.districtInfo);
        setStateInfo(response.data.data.stateInfo);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    getDistrictsCity();
  }, []);

  const [data, setData] = useState([]);
  const [records, setRecords] = useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  function handlefilter(event) {
    const searchQuery = event.target.value.toLowerCase();

    const newData = data.filter((row) => {
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const value = row[key];
          const valueString =
            typeof value === "string" || typeof value === "number"
              ? value.toString().toLowerCase()
              : "";
          if (valueString.includes(searchQuery)) {
            return true;
          }
        }
      }
      return false;
    });
    setRecords(newData);
  }

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "City name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Content",
      sortable: true,
      cell: (row) => (
        <span>
          {row.content ? (
            <b className="text-success">Yes</b>
          ) : (
            <b className="text-danger">No</b>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <Button
            title="View Content"
            className="m-1"
            variant="warning"
            size="sm"
            onClick={() => viewContent(row.id)}
          >
            <b>View</b>
          </Button>
          <Button
            title="Generate Content"
            className="m-1"
            variant="secondary"
            size="sm"
            onClick={() => generateNewContent(row.id)}
          >
            <FaSync />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={6}>
                    <h4>City Communities</h4>
                    <Link to={"/admin/state-communities"}>
                      {stateInfo.name}
                    </Link>{" "}
                    /{" "}
                    <Link to={`/admin/communities/${state_id}`}>
                      {districtInfo.name}
                    </Link>{" "}
                    / City
                  </Col>
                  <Col md={6} className="text-end">
                    <Button
                      title="Add City"
                      size="sm"
                      variant="success"
                      onClick={() => setModalShow(true)}
                    >
                      <FaPlus />
                    </Button>
                    &nbsp;
                    <Button
                      size="sm"
                      variant="warning"
                      title="Upload csv file to add City"
                      onClick={() => setFileModal(true)}
                    >
                      <FaCloudUploadAlt />
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="datatable">
                  <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      onChange={handlefilter}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={records}
                    // selectableRows
                    pagination
                    highlightOnHover
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Add city model */}
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Add City</h3>
            <Form onSubmit={handelSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  value={districtInfo.name}
                  className="shadow-none"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>City Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  placeholder="Enter City name"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleImageChange}
                />
              </Form.Group>
              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end add city modal */}
        {/* View content */}
        <Modal
          show={contentModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">
              View {contentData?.name} District Content
            </h3>
            <hr />
            <div className="p-1 text-center">
              {contentData.img && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}assets/city/${contentData.img}`}
                  className="mb-2"
                  alt=""
                />
              )}
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: contentData.content
                    ? contentData.content
                    : "No content found.",
                }}
              ></div>
            </div>
            <div className="zip-submit-btn d-flex justify-content-end">
              <span
                className="btn btn-danger"
                onClick={() => setContentModal(false)}
              >
                Close
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {/* View content */}
        {/* Add File upload model */}
        <Modal
          show={fileModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Upload City csv file</h3>
            <Form onSubmit={uploadCsvFile}>
              <Form.Group className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  className="shadow-none"
                  value={districtInfo.name}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select csv file</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  required
                  accept=".csv"
                  onChange={handleFileInputChange}
                />
              </Form.Group>
              <a
                href={`${process.env.REACT_APP_BASE_URL}assets/demo-csv/city.csv`}
                download="state.csv"
              >
                Sample csv file
              </a>
              <Alert variant="warning">
                Please do not refresh or close the window while the file is
                uploading. This may take some time, especially for large files.
                Additionally, records already existing in the database will be
                skipped during the upload process.
              </Alert>
              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setFileModal(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end File upload modal */}
      </Container>
    </Dashboardlayout>
  );
};

export default CommunitiesCity;
