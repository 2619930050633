import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Navbar, Nav, NavDropdown, Container, Badge } from "react-bootstrap";
import {
  FaRegBell,
  FaRegUser,
  FaSignOutAlt,
  FaShoppingCart,
} from "react-icons/fa";
import adminImg from "../../assets/admin.png";
import "../../Style/custom.css";

const Adminheader = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [notification, setNotification] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationDataOlder, setNotificationDataOlder] = useState([]);
  const handleDelete = () => {
    localStorage.clear();
    navigate("/login");
  };

  const fetchOrderCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/notification`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        setNotification(response.data.data.newOrderCount.length);
        setNotificationData(response.data.data.newOrderCount);
        setNotificationDataOlder(response.data.data.olderOrder);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {
    fetchOrderCount();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchOrderCount();
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);
  const handleReaded = async(id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/read/notification/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const userData = JSON.parse(localStorage.getItem("userData"));

  if (!userData) {
    navigate("/login");
    return null;
  }

  return (
    <div className="justify-content-end headerBar">
      <Navbar expand="lg" className="bg-body-tertiary dashboad-navbar">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end notification-drop">
          <Nav className="ml-auto">
            <NavDropdown
              className="mt-1"
              title={
                <div>
                  <FaRegBell size={25} />
                  <Badge
                    pill
                    variant="danger"
                    className="position-absolute top-0 notification-icon translate-middle"
                  >
                    {notification}
                  </Badge>
                </div>
              }
              id="basic-nav-dropdown"
            >
              {notificationData.length > 0 && (
                notificationData.map((item) => (
                  <div key={item.id}>
                    <NavDropdown.Item
                      style={{ fontWeight: "bold" }}
                      onClick={() => handleReaded(item.id)}
                    >
                      <Link
                        to={`/admin/order/${item.id}`}
                        className="text-decoration-none"
                      >
                        {`Agent ${item.user.name} has a new order. The order amount is ${item.total}.`}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </div>
                ))
              )}
              {notificationDataOlder.map((item) => (
                <div key={item.id}>
                  <NavDropdown.Item>
                    <Link
                      to={`/admin/order/${item.id}`}
                      className="text-decoration-none"
                    >
                      {`Agent ${item.user.name} has a new order. The order amount is ${item.total}.`}
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </div>
              ))}
            </NavDropdown>
            <NavDropdown
              title={
                <img
                  src={adminImg}
                  width={30}
                  alt="Admin"
                  className="rounded-circle"
                />
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item disabled>
                {userData.name.toUpperCase()}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              {/* <NavDropdown.Item>
                <FaRegBell /> Notification
              </NavDropdown.Item> */}
              <NavDropdown.Item>
                <FaRegUser /> Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleDelete}>
                {" "}
                <FaSignOutAlt /> Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Adminheader;
