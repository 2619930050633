import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import { Container, Row, Col, Card } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const Dashboard = () => {
  const [adminData, setAdminData] = useState([]);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const getAdminData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/dashboard`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log(response.data.data);
      setAdminData(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {
    getAdminData();
  }, []);
  return (
    <Dashboardlayout>
      <Container fluid>
        <Row className="p-3">
          <Col md={4} lg={4}>
            <Link to="/admin/agentsview" style={{ textDecoration: "none" }}>
              <Card bg="success" text="white" className="mb-2" key={1}>
                <Card.Body>
                  <Card.Title>Active Agents</Card.Title>
                  <h3>{adminData.activeAgentCount?.toLocaleString()}</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col md={4} lg={4}>
            <Link to="/admin/agentsview" style={{ textDecoration: "none" }}>
              <Card bg="danger" text="white" className="mb-2" key={2}>
                <Card.Body>
                  <Card.Title>Inactive agents</Card.Title>
                  <h3>{adminData.inActiveAgentCount?.toLocaleString()}</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} lg={4}>
            <Link to="/admin/zipcode" style={{ textDecoration: "none" }}>
              <Card bg="primary" text="white" className="mb-2" key={3}>
                <Card.Body>
                  <Card.Title>Total Zip Code</Card.Title>
                  <h3>{adminData.totalZipCode?.toLocaleString()}</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row className="p-3">
          <Col md={4} lg={4}>
            <Link to="/admin/orders" style={{ textDecoration: "none" }}>
              <Card bg="secondary" text="white" className="mb-2" key={4}>
                <Card.Body>
                  <Card.Title>Active Subscriptions</Card.Title>
                  <h3>
                    {adminData.claimZip?.toLocaleString()} / {adminData.totalZipCode?.toLocaleString()}
                  </h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} lg={4}>
            <Link to="/admin/orders" style={{ textDecoration: "none" }}>
              <Card bg="info" text="white" className="mb-2" key={5}>
                <Card.Body>
                  <Card.Title>Canceled Zip Code</Card.Title>
                  <h3>{adminData.cancelledZip?.toLocaleString()}</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col md={4} lg={4}>
            <Link to="/admin/contact-us" style={{ textDecoration: "none" }}>
              <Card bg="warning" text="white" className="mb-2" key={6}>
                <Card.Body>
                  <Card.Title>Open contact query</Card.Title>
                  <h3>{adminData.openQuery?.toLocaleString()}</h3>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </Dashboardlayout>
  );
};

export default Dashboard;
