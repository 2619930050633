import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";

const MapboxComponent = ({ handleNormalZipClick, handleAddToCart,data }) => {
  // console.log(data);
  const navigate = useNavigate();
  const [loadedState, setLoadedState] = useState("CA");
  const [map, setMap] = useState(null);
  const authToken = localStorage.getItem("token");
  // console.log(authToken);
  const [mapData, setMapData] = useState({
    sold_zip: [],
    normal_zip: [],
    remaining_zip: [],
  });
  const [longitude, setLongitude] = useState(-98.5795); // -74.5
  const [latitude, setLatitude] = useState(39.8283); // 40
  const [zoom, setZoom] = useState(3.4); // 40
  const [markerCor, setMarkerCor] = useState([]);

  const mapContainerRef = useRef(null);

  const fetchData = async (state = null) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}zipcodes-new?state=${
          state || loadedState
        }`,
        {
          withCredentials: true,
        }
      );
      if (state) {
        setLoadedState(state);
      }
      setMapData(response.data.data);
      // console.log(response.data.data);
      updateMapData(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.error(error);
        // NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const updateMapData = (newData) => {
    // console.log(map);
    if (map!=null) {
      if(map?.getSource("custom-tileset")){
        map.getSource("custom-tileset").setData({
          type: "FeatureCollection",
          features: [
            ...generateFeatures(newData.sold_zip, "#FF0000", 0.8),
            ...generateFeatures(newData.normal_zip, "#00FF00", 0.8),
            ...generateFeatures(newData.remaining_zip, "#ffff00", 0.8),
          ],
        });
      }
     
    } else {
      console.error("Source custom-tileset not found or not yet initialized.");
    }
  };

  const initializeMap = () => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;

    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/listelligent/clsong30100mx01qudtwjdbu8",
      center: [longitude, latitude],
      zoom: zoom,
    });

    setMap(newMap);

    newMap.on("load", () => {
      newMap.addSource("custom-tileset", {
        type: "vector",
        url: `mapbox://listelligent.c78xjzrr`,
      });

      newMap.addSource("states", {
        type: "geojson",
        data: "/usa_state.geojson",
      });

      newMap.addLayer({
        id: "states-layer",
        type: "fill",
        source: "states",
        paint: {
          "fill-color": "rgba(200, 100, 240, 0.4)",
          "fill-outline-color": "rgba(200, 100, 240, 1)",
        },
      });

      newMap.on("click", "states-layer", (e) => {
        const points = e.lngLat;
        const stateName = e.features[0].properties.name;
        const stateCode = e.features[0].properties.postal;
        const coords = e.features[0].geometry.coordinates[0];
        let lng = points.lng;
        let lat = points.lat;
        // if (Array.isArray(coords[0][0])) {
        //   lng = coords[0][0][0];
        //   lat = coords[0][1][1];
        // } else {
        //   lng = coords[0][0];
        //   lat = coords[0][1];
        // }

        if (stateCode && stateCode !== loadedState) {
          fetchData(stateCode);
          setLongitude(lng);
          setLatitude(lat);
          setZoom(10);
        } else {
          setLongitude(lng);
          setLatitude(lat);
          newMap.setCenter([lng, lat]);
          newMap.setZoom(10);
        }
      });

      newMap.addLayer({
        id: "custom-layer",
        type: "fill",
        source: "custom-tileset",
        "source-layer": "zip_code_polygons-czg30d",
        paint: {
          "fill-color": [
            "match",
            ["get", "Zip_Code"],
            ...generateColorMapping(mapData.sold_zip, "#FF0000"),
            ...generateColorMapping(mapData.normal_zip, "#00FF00"),
            ...generateColorMapping(mapData.remaining_zip, "#ffff00"),
            "#9aeb93",
          ],
          "fill-opacity": [
            "match",
            ["get", "Zip_Code"],
            ...generateOpacityMapping(mapData.sold_zip, 0.8),
            ...generateOpacityMapping(mapData.normal_zip, 0.8),
            ...generateOpacityMapping(mapData.remaining_zip, 0.8),
            0.1,
          ],
          "fill-outline-color": "#5d6164",
          "fill-outline-width": 10,
        },
      });

      newMap.addLayer({
        id: "custom-layer-label",
        type: "symbol",
        source: "custom-tileset",
        "source-layer": "zip_code_polygons-czg30d",
        layout: {
          "text-field": ["get", "Zip_Code"],
          "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
          "text-size": 14,
          "text-anchor": "center",
        },
        paint: {
          "text-color": "#000000",
        },
      });

      newMap.addLayer({
        id: "custom-layer-hover",
        type: "fill",
        source: "custom-tileset",
        "source-layer": "zip_code_polygons-czg30d",
        paint: {
          "fill-color": "#00cc00",
          "fill-opacity": 0.5,
          "fill-outline-color": "#5d6164",
          "fill-outline-width": 10,
        },
        filter: ["==", "Zip_Code", ""],
      });

      return () => {
        newMap.remove(); // Clean up the map instance on component unmount
      };
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: "us",
    });

    newMap.addControl(geocoder);
    document
      .querySelectorAll(".mapboxgl-marker")
      .forEach((marker) => marker.remove());
    if (markerCor.length > 0) {
      new mapboxgl.Marker().setLngLat(markerCor).addTo(newMap);
    }
    geocoder.on("result", (event) => {
      const selectedResult = event.result;

      const isUS =
        selectedResult.context &&
        selectedResult.context.some(
          (item) => item.short_code && item.short_code.startsWith("US-")
        );

      if (isUS) {
        const stateCodeItem = selectedResult.context.find(
          (item) => item.short_code && item.short_code.startsWith("US-")
        );

        const getMarkerCoordinate = selectedResult.center;

        let stateCode = null;

        if (stateCodeItem) {
          stateCode = stateCodeItem.short_code.split("-")[1];
        }

        if (stateCode && stateCode !== loadedState) {
          fetchData(stateCode);
        }

        setMarkerCor([getMarkerCoordinate[0], getMarkerCoordinate[1]]);
        setLongitude(getMarkerCoordinate[0]);
        setLatitude(getMarkerCoordinate[1]);
        setZoom(10);

        document
          .querySelectorAll(".mapboxgl-marker")
          .forEach((marker) => marker.remove());

        new mapboxgl.Marker()
          .setLngLat([getMarkerCoordinate[0], getMarkerCoordinate[1]])
          .addTo(newMap);

        newMap.flyTo({
          center: [getMarkerCoordinate[0], getMarkerCoordinate[1]],
          zoom: 10,
          speed: 1.5,
          curve: 1,
        });
      } else {
        console.log("Result is not in the United States");
      }
    });

    newMap.on("mousemove", "custom-layer", (e) => {
      if (e.features.length > 0) {
        const { Zip_Code } = e.features[0].properties;

        newMap.getCanvas().style.cursor = "pointer";

        const existingPopups = document.querySelectorAll(".mapboxgl-popup");
        existingPopups.forEach((popup) => popup.remove());

        handleMapHover(Zip_Code, newMap, e.lngLat);
      }
    });

    newMap.on("click", "custom-layer", (e) => {
      // console.log(e.features);
      if (e.features.length > 0) {
        const { Zip_Code, state } = e.features[0].properties;
        handleMapClick(Zip_Code, state, newMap, e, mapData);
        // console.log("Clicked on Zip Code:", Zip_Code);
        // console.log("Clicked on State:", state);
      }
    });

    newMap.on("mouseenter", "custom-layer", () => {
      newMap.getCanvas().style.cursor = "pointer";
    });

    newMap.on("mouseleave", "custom-layer", () => {
      newMap.getCanvas().style.cursor = "";
      newMap.setFilter("custom-layer-hover", ["==", "Zip_Code", ""]);
      const existingPopups = document.querySelectorAll(".mapboxgl-popup");
      existingPopups.forEach((popup) => popup.remove());
    });

    return () => {
      newMap.remove();
    };
  };

  const handleMapHover = (zip, map, lngLat) => {
    if (mapData.normal_zip.includes(zip)) {
      const isSoldZip = mapData.sold_zip.includes(zip);

      map.setFilter("custom-layer-hover", ["==", "Zip_Code", zip]);
      map.setPaintProperty(
        "custom-layer-hover",
        "fill-color",
        isSoldZip ? "#FF0000" : "#00cc00"
      );
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(lngLat)
        .setHTML(
          `<p style="color: #FF6C2C; font-size: 16px;">Click to add to cart and view</p>`
        )
        .addTo(map);
    } else if (
      mapData.remaining_zip.some((zipObj) => zipObj.zip_code === zip)
    ) {
      const zipObject = mapData.remaining_zip.find(
        (zipObj) => zipObj.zip_code === zip
      );

      map.setFilter("custom-layer-hover", ["==", "Zip_Code", zip]);
      map.setPaintProperty(
        "custom-layer-hover",
        "fill-color",
        zipObject ? "#ffff00" : "#00cc00"
      );
      if (zipObject) {
        const { zip_code, sold } = zipObject;
        new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(lngLat)
          .setHTML(
            `<p style="color: #FF6C2C; font-size: 16px;">This zip code ${zip_code} has ${
              2 - sold
            } left.</p>`
          )
          .addTo(map);
      }
    } else if (mapData.sold_zip.includes(zip)) {
      map.setFilter("custom-layer-hover", ["==", "Zip_Code", zip]);
      map.setPaintProperty("custom-layer-hover", "fill-color", "#FF0000");
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(lngLat)
        .setHTML(
          '<p style="color: red; font-size: 16px;">This zip code is <b>Sold</b></p>'
        )
        .addTo(map);
    }
  };

  const handleMapClick = (zip, state, map, e, mapData) => {
    if (
      mapData.normal_zip.includes(zip) ||
      mapData.remaining_zip.some((zipObj) => zipObj.zip_code === zip)
    ) {
      handleNormalZipClick(zip);
    } else {
      // console.log(e);
      const clickedFeature = e.features[0];
      const coord = clickedFeature.geometry.coordinates[0];
      // if (Array.isArray(coord[0][0])) {
      //   setLongitude(coord[0][0][0]);
      //   setLatitude(coord[0][1][1]);
      // } else {
      //   setLongitude(coord[0][0]);
      //   setLatitude(coord[0][1]);
      // }
      const points = e.lngLat;
      let lng = points.lng;
      let lat = points.lat;
      setLongitude(lng);
      setLatitude(lat); //coord[0][1]
      fetchData(state);
    }
  };

  useEffect(() => {
    // console.log(loadedState);
    fetchData();
  }, [loadedState]);

  useEffect(() => {
    if (
      mapData.sold_zip.length > 0 ||
      mapData.normal_zip.length > 0 ||
      mapData.remaining_zip.length > 0
    ) {
      initializeMap();
    }
  }, [mapData]);

  const generateColorMapping = (zipArray, color) => {
    // return zipArray.reduce((acc, zip) => [...acc, zip, color], []);
    return zipArray.reduce((acc, zip) => {
      const zipValue = typeof zip === "object" ? zip.zip_code : zip;
      return [...acc, zipValue, color];
    }, []);
  };

  const generateOpacityMapping = (zipArray, opacity) => {
    return zipArray.reduce((acc, zip) => {
      const zipValue = typeof zip === "object" ? zip.zip_code : zip;
      return [...acc, zipValue, opacity];
    }, []);
  };

  const generateFeatures = (zipArray, color, opacity) => {
    return zipArray.map((zip) => {
      const zipCode = typeof zip === "object" ? zip.zip_code : zip;
      return {
        type: "Feature",
        properties: {
          zip: zipCode,
        },
        geometry: {
          type: "Polygon",
          coordinates: [],
        },
        paint: {
          "fill-color": color,
          "fill-opacity": opacity,
          "fill-outline-color": "#5d6164",
          "fill-outline-width": 10,
        },
      };
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id="map"
        ref={mapContainerRef}
        style={{ width: "100%", height: "60vh", borderRadius: "10px" }}
      />
    </div>
  );
};

export default MapboxComponent;
