// utils/dateUtils.js
import moment from 'moment-timezone';

// Configure default timezone
const TIMEZONE = "Asia/Kolkata"; // Set your preferred timezone

// Convert a date to the specified timezone
export const toTimezone = (date, timezone = TIMEZONE) => {
  return moment.tz(date, timezone).format('YYYY-MM-DD HH:mm:ss');
};

// Parse a date from the specified timezone
export const parseDate = (dateString, timezone = TIMEZONE) => {
  return moment.tz(dateString, timezone).toDate();
};

// Get the current date in the specified timezone
export const nowInTimezone = (timezone = TIMEZONE) => {
  return moment.tz(timezone).format('YYYY-MM-DD HH:mm:ss');
};
