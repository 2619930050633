import React, { useState, useEffect } from "react";
import "../Style/home.css";
import "../Style/custom.css";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import usStates from "./usStates";
import Loader from "../components/Loader";
import ConstructionPage from "./ConstructionPage";

const StateList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [splitContent, setSplitContent] = useState({});
  const statesPerColumn = Math.ceil(usStates.length / 4);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const convertToSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    navigate(`/state/${text}`);
  };
  return (
    <Layout>
      {loading && <Loader />}
      <div className="first-section">
        <NotificationContainer />
        <Container>
          <Row>
            <Col>
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading">
                    <h1 className="orangeColorText">State of United States</h1>
                  </Card.Title>
                  <Row xs={1} md={4} className="g-4 mt-3 txtBlack">
                    {usStates.map((data, idx) => (
                      <Col key={idx} className="m-0 text-center p-1">
                        <Link to={convertToSlug(`${data.label}`)}>
                        <b>{data.label}</b>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default StateList;
