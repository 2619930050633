import React from "react";
import "../../Style/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactUsModal from "../ContactUsModal";
import orangeLogo from "../../assets/listelligent-logo-orange.png";
import whiteLogo from "../../assets/listeligent-logo-white.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <ContactUsModal />
        <Row>
          <Col md={3}>
            <img
              src={whiteLogo}
              alt="Listelligent Logo"
              height="30"
              className=" h4"
            />
            <p>
              Listelligent connects you with top agents that will list your home
              for 1% .
            </p>
            {/* <p>{process.env.REACT_APP_ADDRESS_2}</p> */}
          </Col>
          <Col md={3}></Col>
          <Col md={3}>
            <h4>Company</h4>
            <ul className="footer-link">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/how-it-works"}>How It Works</Link>
              </li>
              <li>
                <Link to={"/login"}>Agent Sign In</Link>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h4>Legal</h4>
            <ul className="footer-link pb-0">
              <li>
                <Link to={"/term-condition"}>Terms Of Service</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={"/cookie-policy"}>Cookies</Link>
              </li>
              <li className="p-0">
                <Link to={"/disclaimer"}>Disclaimer</Link>
              </li>
            </ul>
          </Col>
          {/* <Col md={3}></Col>
          <Col md={3}></Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
