import React, { useState, useEffect } from "react";
import "../Style/home.css";
import "../Style/agentlist.css";
import "../Style/custom.css";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BsGeoAltFill } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { CloseButton } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import ProgressBar from "react-bootstrap/ProgressBar";
import Box from "@mui/material/Box";
import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { AiFillPlayCircle } from "react-icons/ai";
import homeImage from "../../src/assets/home_img.jpg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import usStates from "./usStates";
import { BiInfoCircle } from "react-icons/bi";
import seenLogos from "../assets/seen_on_logos.png";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineOtherHouses } from "react-icons/md";
import { MdOutlineRealEstateAgent } from "react-icons/md";

const Home = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const [modalShow, setModalShow] = React.useState(false);
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [modalShowAddress, setModalShowAddress] = useState(false);
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const storedFormDataString = localStorage.getItem("formData");
  const storedFormData = storedFormDataString
    ? JSON.parse(storedFormDataString)
    : null;
  const [cookies, setCookie] = useState(storedFormData);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSubmit, setisSubmit] = useState(false);
  const [homeAgentZipCode, setHomeAgentCode] = useState({
    agent_zip_code: "",
  });
  const [formData, setFormData] = useState({
    name: cookies ? cookies["name"] : "",
    phone: cookies ? cookies["phone"] : "",
    email: cookies ? cookies["email"] : "",
    address: cookies ? cookies["address"] : "",
    zip_code: cookies ? cookies["zip_code"] : "",
    agent_zip_code: cookies ? cookies["agent_zip_code"] : "",
    state: cookies ? cookies["state"] : "",
  });

  const [defaultRange, setRange] = useState(1650000);
  const [onePer, setOnePer] = useState(0);
  const [twoPer, setTwoPer] = useState(0);
  const [threePer, setThreePer] = useState(0);
  useEffect(() => {
    setOnePer((defaultRange * 1) / 100);
    setTwoPer((defaultRange * 2) / 100);
    setThreePer((defaultRange * 3) / 100);
  }, [defaultRange]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handlePriceChange = (event) => {
    setRange(event.target.value);
    setOnePer((event.target.value * 1) / 100);
    setTwoPer((event.target.value * 2) / 100);
    setThreePer((event.target.value * 3) / 100);
  };

  const steps = [
    {
      label: "Local Professionals",
      description: `Work with top agents in the area`,
    },
    {
      label: "Review Profile",
      description: "Request more information by setting up an interview",
    },
    {
      label: "No Hidden Fees",
      description: `Listelligent is completely free to use.`,
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (cookies) {
      setIsOpen(false);
    }
  }, []);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "agent_zip_code") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        zip_code: value,
      }));
    }
  };

  const handleChangeHomeZipCode = (e) => {
    const { name, value } = e.target;
    setHomeAgentCode((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    localStorage.setItem("HomeAgentCode", value);
  };

  const handleSubmitzPro = (e) => {
    e.preventDefault();
    // if (formData.address === "" || formData.agent_zip_code === "") {
    if (formData.address === "") {
      NotificationManager.error("Error", "Please fill all field", 3000);
      return false;
    }
    updateField("address", formData.address);
    updateField("agent_zip_code", formData.agent_zip_code);
    setIsOpen((isOpen) => !isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsOpen((isOpen) => !isOpen);
    setisSubmit(true);
    redirectAgentList();
    // addUserLead();
  };

  const updateField = (fieldName, newValue) => {
    setCookie((prevState) => {
      const newState = { ...prevState };
      newState[fieldName] = newValue;
      localStorage.setItem("formData", JSON.stringify(newState));
      return newState;
    });
  };

  const redirectAgentList = () => {
    addUserLead();
  };

  const checkAndRedirect = async () => {
    if (address) {
      navigate("/agentslist");
    } else {
      NotificationManager.error("Error", "Please fill Address", 3000);
    }
  };

  const addUserLead = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addLead`,
        formData,
        {
          headers: {
            Authorization: `Bearer 455454545ddsdksdjksdjksdsjdksjdksj`,
          },
          withCredentials: true,
        }
      );
      if (response.status) {
        localStorage.setItem("formData", JSON.stringify(formData));
        // setIsOpen(true)
        // setIsOpen((isOpen) => !isOpen);
        navigate("/agentslist");
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);
      const zipCodeComponent = results[0].address_components.find((component) =>
        component.types.includes("postal_code")
      );

      const newZipCode = zipCodeComponent ? zipCodeComponent.short_name : "";
      const stateComponent = results[0].address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );

      // If state code is found, extract its short name
      const stateCode = stateComponent ? stateComponent.short_name : "";
      setAddress(selectedAddress);
      formData.address = selectedAddress;
      setZipCode(newZipCode);
      formData.agent_zip_code = newZipCode;
      formData.zip_code = newZipCode;
      formData.state = stateCode;
      // setHomeAgentCode({ agent_zip_code: newZipCode });
      // localStorage.setItem("HomeAgentCode", newZipCode);
      localStorage.setItem("state", stateCode);
    } catch (error) {
      console.error("Error fetching location information:", error);
    }
  };
  const statesPerColumn = Math.ceil(usStates.length / 4);

  const handleLiClick = (value, label) => {
    localStorage.setItem("state", value);
    localStorage.setItem("state_name", label);
    localStorage.removeItem("agent_zip_code");
    navigate("/agentslist");
  };

  const viewDistrict = (state_code, state) => {
    const slug = state
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    navigate(`/state/${slug}`);
    // return text
    //   .toLowerCase()
    //   .replace(/ /g, '-')
    //   .replace(/[^\w-]+/g, '');
  };
  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };
  return (
    <Layout>
      <div
        className="form-container"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <Container>
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className="form">
                <h3>One Step Away From Huge Savings!</h3>

                <Form onSubmit={handleSubmit}>
                  <Form.Control
                    className="mt-5 mb-4"
                    name="name"
                    size="lg"
                    type="text"
                    placeholder="First and Last Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-4 mb-4"
                    name="phone"
                    size="lg"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  {/* <div className='or'><span>OR</span></div> */}
                  <Form.Control
                    className="mt-1 mb-4"
                    name="email"
                    size="lg"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-1 mb-4"
                    name="address"
                    size="lg"
                    type="text"
                    required
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  <Form.Control
                    className="mt-1 mb-4 zip_number"
                    name="zip_code"
                    size="lg"
                    type="number"
                    placeholder="Zip code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    required
                  />
                  <Button type="submit" variant="warning">
                    Continue
                  </Button>{" "}
                </Form>
                <div className="mt-4 text-center">
                  <p>
                    By continuing, you agree to receive emails and texts from
                    listelligent.com and other parties regarding your inquiry
                    and other home-related matters, including marketing via
                    automated voice solutions, prerecorded and artificial voice,
                    and text messages. This consent is provided independently of
                    any purchase you make, and it is applicable whether you
                    check or leave unchecked any of the above
                    boxes.Additionally, you accept our{" "}
                    <Link
                      to="/term-condition"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </Link>{" "}
                    ,{" "}
                    <Link
                      to="/cookie-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie policy
                    </Link>{" "}
                    and our{" "}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>{" "}
                    with reference to the data we collect about you. There may
                    be message and data fees. Even if your name is on a
                    corporate, state, or federal do-not-call list, this consent
                    is still valid.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {" "}
              <CloseButton onClick={toggle} aria-label="Close" />
            </Col>
          </Row>
        </Container>
      </div>
      {/* {isSubmit && (
        <div
          className="steper-container"
          style={{ display: isSubmit ? "block" : "none" }}
        >
          <Container>
            <Row>
              <Col lg={3}></Col>
              <Col lg={6} className="steper-content">
                <h3>Locating agents based on your zip code</h3>
                <Box sx={{ maxWidth: 600 }} className="sterper">
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step key={step.label} className="agentlist-step-label">
                        <StepLabel
                          optional={
                            index === 2 ? (
                              <Typography variant="caption">
                                Last step
                              </Typography>
                            ) : null
                          }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <Box sx={{ mb: 2 }}>
                            <div>
                              <Button
                                className="agentlist-steper-btn"
                                variant="contained"
                                onClick={() => {
                                  handleNext();
                                  if (index === steps.length - 1) {
                                    redirectAgentList();
                                  }
                                }}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === steps.length - 1
                                  ? "Finish"
                                  : "Continue"}
                              </Button>
                            </div>
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <ProgressBar now={60} />
              </Col>
              <Col lg={3}></Col>
            </Row>
          </Container>
        </div>
      )} */}
      <div
        className="first-section"
        style={{
          display: isOpen || isSubmit ? "none" : "block",
          paddingBottom: "5rem",
        }}
      >
        <NotificationContainer />
        <Container>
          <Row
            className="mt-3  first-inner-section mt-5"
            style={{ minHeight: "80vh" }}
          >
            <Col
              md={6}
              className="first-left-col d-flex flex-column justify-content-center p-5"
            >
              <h4 className="mb-3">
                Sell Your Home for <span className="highLightText">1%</span>
                <sup style={{ top: "-1.5em" }}>
                  <Tooltip
                    title="*Buyer’s agent fee not included. Consult with your agent on local broker co-op commissions."
                    arrow
                  >
                    <span>
                      <BiInfoCircle />
                    </span>
                  </Tooltip>
                </sup>
              </h4>
              <h4 className="mb-4">Connect With Agents in Your Zip Code</h4>
              <h5 className="mb-4">
                Enter your address to match with a local professional
              </h5>
              {/* <div className="zip-search-input"> */}
              <div className="mb-4">
                <Form onSubmit={handleSubmitzPro}>
                  {/* <InputGroup
                    className="mt-3 zip-search-input-content"
                    style={{ width: "100%" }}
                  > */}
                  {/* <Form.Control
                      placeholder="Enter Address"
                      className="shadow-none"
                      name="address"
                      type="text"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    /> */}
                  {/* <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="position-relative mt-2 mb-4">
                        <input
                          {...getInputProps({
                            placeholder: "Type address",
                            className:
                              "form-control custom-input-radius custom-input-height",
                          })}
                        />

                        {suggestions.length > 0 && (
                          <div
                            className="position-absolute start-0 mt-2"
                            style={{
                              background: "#fff",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              zIndex: 1000,
                              width: "550px",
                            }}
                          >
                            <ul className="list-group">
                              {loading ? (
                                <li className="list-group-item">Loading...</li>
                              ) : (
                                suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };

                                  return (
                                    <li
                                      className="list-group-item"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </li>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete> */}

                  {/* <Button className='zip-code-btn'><BsGeoAltFill />Zip Code</Button> */}
                  {/* <BsGeoAltFill /> */}
                  {/* <Form.Control
                      type="text"
                      name="agent_zip_code"
                      placeholder="Zip Code"
                      className="shadow-none"
                      value={formData.agent_zip_code}
                      onChange={handleChange}
                      required
                    /> */}
                  {/* </InputGroup> */}
                  <InputGroup className="mt-3 zip-search-input-content home-search-bar">
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="position-relative homeAddress">
                          <input
                            {...getInputProps({
                              placeholder: "Type address",
                              className:
                                "form-control custom-input-radius custom-input-height shadow-none",
                            })}
                          />

                          {suggestions.length > 0 && (
                            <div
                              className="position-absolute start-0 mt-2"
                              style={{
                                background: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                zIndex: 1000,
                                width: "100%",
                              }}
                            >
                              <ul className="list-group">
                                {loading ? (
                                  <li className="list-group-item">
                                    Loading...
                                  </li>
                                ) : (
                                  suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                    };

                                    return (
                                      <li
                                        className="list-group-item"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </li>
                                    );
                                  })
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <BsGeoAltFill />
                    <Form.Control
                      type="text"
                      placeholder="Zip Code"
                      className="shadow-none"
                      name="agent_zip_code"
                      value={formData.agent_zip_code}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                  <div className="text-center mt-2">
                    <Button
                      type="submit"
                      className="find-btn custom-button-css custom-button-orange-bg mt-3"
                    >
                      Find A Pro
                    </Button>
                  </div>
                </Form>
              </div>
              <p>No Obligation-No Spam-Fast-Simple</p>
            </Col>
            <Col
              md={6}
              className="home-img d-flex flex-column justify-content-center p-5"
            >
              <img
                className="rounded  img-fluid"
                loading="lazy"
                src={homeImage}
                alt="Image"
              ></img>
            </Col>
          </Row>
          <Row className="second-inner-section">
            <div className="justify-content-center">
              <div className="d-flex justify-content-center seen-on-logs-head">
                <h4>As Seen On</h4>
              </div>
            </div>
            <Row
              className="justify-content-center"
              style={{ paddingBottom: "5rem" }}
            >
              <Col
                md={8}
                className="justify-content-center"
                style={{ paddingRight: "0px" }}
              >
                <img src={seenLogos} alt="seen-on-logos" />
              </Col>
            </Row>
            <Col md={4} className="agent-col">
              {/* <MdOutlineOtherHouses /> */}

              <span class="material-symbols-outlined">real_estate_agent</span>

              <h5>Find Local Agents</h5>
              <p>
                Insert your address to connect with top agents that list for 1%
              </p>
            </Col>
            <Col md={4}>
            <span class="material-symbols-outlined">groups</span>
              <h5>Connect</h5>
              <p>Review agent profile and schedule an interview</p>
            </Col>
            <Col md={4}>
              {/* <CiLocationOn /> */}
            <span class="material-symbols-outlined">home_pin</span>

              <h5>Save Thousands of Dollars!</h5>
              <p>Work with your agent to get your home sold!</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div
        className={`vs-section ${isOpen || isSubmit ? "hidden" : "visible"}`}
      >
        <div className="container">
          <div className="py-4">
            <h2>A better partner for seller leads.</h2>
          </div>
          <div className="hp-content">
            <div className="left">
              <div className="text-end content-desc">
                <p className="firstContent">
                  <img src={orgLogo} width={200} />
                </p>
                <p>Exclusive Zip Codes <FaCheckCircle size={15} color="#ff6c2c"/></p>
                <p>No Contracts <FaCheckCircle size={15} color="#ff6c2c"/></p>
                <p>No Referral Fees <FaCheckCircle size={15} color="#ff6c2c"/></p>
                <p>Google Pay Per Click Sponsored Ads <FaCheckCircle size={15} color="#ff6c2c"/></p>
                <p>Free SEO Traffic <FaCheckCircle size={15} color="#ff6c2c"/></p>
                <p>Option To Increase Lead Flow <FaCheckCircle size={15} color="#ff6c2c"/></p>
              </div>
            </div>
            <div className="middle">
              <div className="line"></div>
              <div className="vs-circle">VS</div>
            </div>
            <div className="right">
              <div className="text-start content-desc">
                <p className="firstContent">
                  <h4>Other Lead Gen Platforms</h4>
                </p>
                <p></p>
                <p><IoMdCloseCircle /> Shared Zip Codes</p>
                <p><IoMdCloseCircle /> Annual Contracts With Cancellation Penalties </p>
                <p><IoMdCloseCircle /> Hefty Referral Fees</p>
                <p><IoMdCloseCircle /> No Pay Per Click Ads</p>
                <p><IoMdCloseCircle /> No SEO</p>
                <p><IoMdCloseCircle /> Fixed Lead Flow</p>
              </div>
            </div>
          </div>
          <div className="p-2 getStarted-btn">
            <Button
              type="button"
              onClick={() => setModalShowAddress(true)}
              className="find-btn custom-button-css custom-button-orange-bg mt-3"
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </div> */}
      <div
        className="second-section"
        style={{ display: isOpen || isSubmit ? "none" : "block",paddingBottom:"5rem" }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>How much can I save by using a Listelligent pro?</h1>
              <h1 className="mt-4">Sales Price</h1>
            </Col>
          </Row>

          <Row>
            <Col md={3}></Col>
            <Col md={6} className="rang-label">
              <Form.Label>Listing Price</Form.Label>
              <center>
                <h4>{formatCurrency(defaultRange)}</h4>
              </center>
              <Form.Range
                min={300000}
                max={3000000}
                step={10}
                value={defaultRange}
                onChange={handlePriceChange}
              />
              <div className="d-flex justify-content-between">
                <h5>{formatCurrency(300000)}</h5>
                <h5>{formatCurrency(3000000)}</h5>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>

          <Row className="calculation-colunms justify-content-center">
            <Col md={6} sm={12} className="calculator-stat-listelligent ">
              <p>Agent fees with listelligent</p>
              <p>1%</p>
              <h3>{formatCurrency(onePer)}</h3>
            </Col>
            <Col md={3} sm={12} className="calculator-stat-others">
              <p>Other Agents</p>
              <p>3%</p>
              <h3>{formatCurrency(threePer)}</h3>
            </Col>
            <Col md={3} sm={12} className="calculator-stat-saving">
              <p>Potential Savings:</p>
              <h3>{formatCurrency(twoPer)}</h3>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="third-section"
        style={{ display: isOpen || isSubmit ? "none" : "block",paddingBottom:"5rem" }}
      >
        <Container>
          <Row>
            <Col md={12} className="third-sec-heading">
              <h1>Why work with Listelligent?</h1>
            </Col>
          </Row>
          <Row className="third-sec-content">
            <Col md={6}>
              <h3>Hassle Free Search</h3>
              <p>
                Insert your address and connect with a pro right away! No long
                questionnaires, spam calls, junk emails or any other nonsense.
                Listelligent connects you with top agents that will list your
                home for 1% netting you more money.
              </p>
              <h3>Easy Interviews</h3>
              <p>
                Chat with your local pro to see if they are a good fit for your
                Real Estate needs.
              </p>
              <h3>Save Thousands of Dollars</h3>
              <p>
                Listelligent connects you with top agents that will list your
                home for 1% netting you more money.
              </p>
              <p>
                *Buyer’s agent fee not included. Consult with your agent on
                local broker co-op commissions.
              </p>
            </Col>
            <Col md={6}>
              <div className="video-popup">
                <img
                  src={require("../../src/assets/video_thumbnail_3.webp")}
                  alt="video"
                  className="img-fluid"
                ></img>
                <Link onClick={() => setModalShow(true)}>
                  <AiFillPlayCircle />
                </Link>
                <Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <video
                      src={require("../../src/assets/Promo_video.mp4")}
                      width="100%"
                      height="auto"
                      controls
                      autoPlay
                    ></video>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="fourth-section"
        style={{ display: isOpen || isSubmit ? "none" : "block" }}
      >
        <Container>
          <Row className="align-items-center">
            <Col md={7} className="fourth-sec-heading communitiesHeading">
              <h2 className="text-start">
                Connect With Local 1% Listing Agents
              </h2>
            </Col>
            <Col md={5}>
              <Form className="search-form">
                {/* <Form.Control
                  size="lg"
                  type="number"
                  name="agent_zip_code"
                  onChange={handleChangeHomeZipCode}
                  placeholder="Zip code"
                /> */}
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <input
                        {...getInputProps({
                          placeholder: "Type address",
                          className: "form-control form-control-lg",
                        })}
                      />

                      {suggestions.length > 0 && (
                        <div
                          className="position-absolute start-0 mt-5"
                          style={{
                            background: "#fff",
                            border: "1px solid #ced4da",
                            borderRadius: "0.25rem",
                            zIndex: 1000,
                            width: "550px",
                          }}
                        >
                          <ul className="list-group">
                            {loading ? (
                              <li className="list-group-item">Loading...</li>
                            ) : (
                              suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };

                                return (
                                  <li
                                    className="list-group-item"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </li>
                                );
                              })
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </PlacesAutocomplete>
                <Button onClick={checkAndRedirect}>Search</Button>
              </Form>
            </Col>
          </Row>
          <Row>
            <div className="search-result">
              <h1>Agent Search</h1>
            </div>
            {[0, 1, 2, 3].map((columnIndex) => (
              <Col md={3} key={columnIndex} className="agents-list">
                <ul>
                  {usStates
                    .slice(
                      columnIndex * statesPerColumn,
                      (columnIndex + 1) * statesPerColumn
                    )
                    .map((state) => (
                      <li
                        key={state.value}
                        className="cursorClass"
                        // onClick={() => handleLiClick(state.value, state.label)}
                        onClick={() => viewDistrict(state.value, state.label)}
                      >
                        {state.label}
                      </li>
                    ))}
                </ul>
              </Col>
            ))}
            {/* <Link to={"/singlepost"}>Rhode Island</Link> */}
          </Row>
        </Container>
      </div>
      {/* <Modal
            show={modalShowAddress}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="p-3"
          >
            <Modal.Body className="p-5">
              <h5 className="text-left">Search agent</h5>
              <Form onSubmit={handleSubmit}>
                <InputGroup
                  className="mt-3 zip-search-input-content howitwork"
                  style={{
                    width: "100%",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                >
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="position-relative homeAddress">
                        <input
                          {...getInputProps({
                            placeholder: "Search address",
                            className: "form-control",
                            style: { fontSize: "1.2rem" },
                          })}
                          required
                        />

                        {suggestions.length > 0 && (
                          <div
                            className="position-absolute start-0 mt-2"
                            style={{
                              background: "#fff",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              zIndex: 1000,
                              width: "550px",
                            }}
                          >
                            <ul className="list-group">
                              {loading ? (
                                <li className="list-group-item">Loading...</li>
                              ) : (
                                suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };

                                  return (
                                    <li
                                      className="list-group-item"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </li>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <BsGeoAltFill />
                  <Form.Control
                    type="number"
                    name="agent_zip_code"
                    placeholder="Zip Code"
                    className="shadow-none zip_number"
                    value={formData.agent_zip_code}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>
                <h5 className="text-left mt-4 mb-2">
                  Fill your contact details
                </h5>
                <Form.Control
                  className="mt-3 mb-4"
                  name="name"
                  size="lg"
                  type="text"
                  placeholder="First and Last Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control
                  className="mt-4 mb-4"
                  name="phone"
                  size="lg"
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <Form.Control
                  className="mt-1 mb-4"
                  name="email"
                  size="lg"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <div className="d-flex justify-content-start">
                <button
                  type="submit"
                  className="custom-button-css custom-button-orange-bg"
                >
                  Submit
                </button>
                &nbsp;
                <button
                   variant="danger"
                   className="custom-button-css custom-button-red-bg"
                  onClick={() => setModalShowAddress(false)}
                >
                  Close
                </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal> */}
    </Layout>
  );
};

export default Home;
