import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";

const CheckoutForm = ({ amount, cartItems, promoCode }) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const userdetails= JSON.parse(localStorage.getItem("userData"));
  console.log(userdetails);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement || cardElement._empty || cardElement._invalid) {
      NotificationManager.error(
        "Error",
        "Card details are missing or invalid.",
        3000
      );
      return;
    }
    setLoading(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      NotificationManager.error("Error", error.message, 4500);
      return false;
    }
    if (paymentMethod) {
      const responseInit = await axios.post(
        `${process.env.REACT_APP_BASE_URL}paymentFrontSide`,
        {
          paymentMethod: paymentMethod.id,
          promoCode: promoCode,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!responseInit.status) {
        NotificationManager.error("Error", responseInit.error, 3000);
      } else {
        const { data } = responseInit;
        const confirmPayment = await stripe?.confirmCardPayment(
          data.clientSecret
        );
        // console.log(confirmPayment);
        if (confirmPayment?.error) {
          paymentDeclined();
        } else {
          confirmPayment.paymentIntent.cart_total = amount;
          confirmPayment.paymentIntent.promoCode = promoCode;

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}agent/cart/placeOrder`,
              confirmPayment,
              {
                headers: { Authorization: `Bearer ${authToken}` },
                withCredentials: true,
              }
            );
            if (response.status) {
              // console.log(response.data);
              // const sleep = (ms) =>
              //   new Promise((resolve) => setTimeout(resolve, ms));
              console.log(response.data.carts.length);

              const runSequentially = async (response) => {
                if (response.data.carts.length > 0) {
                  for (let i = 0; i < response.data.carts.length; i++) {
                    try {
                      const cartItem=response.data.carts[i];
                       const name = `${cartItem.city}--${cartItem.zip_code} (${response.data.data.id})`
                      // Call your API function here, for example:
                      const apiResponse = await handleTest(
                        name,
                        cartItem.zip_code,
                        response.data.data.id,
                        response.data.data.user_id,
                        cartItem.price,
                        cartItem.city,
                        cartItem.state

                      );

                      // Handle the API response here if needed
                      console.log(`Response for item ${i}:`, apiResponse);
                    } catch (error) {
                      // Handle any errors that occur during the API call
                      console.error(`Error with item ${i}:`, error);
                    }
                  }
                }
                
              };

              // Assuming you have the response object ready
              runSequentially(response)
              .then(() => {
                // Navigate to another page after all API calls are completed
                // Replace with your navigation logic
                NotificationManager.success(
                  "Success",
                  response.data.message,
                  3000
                );
                localStorage.setItem("orderSuccess", response.data.message);
                const userData = JSON.parse(localStorage.getItem("userData"));
                userData.activeZipcode = cartItems.length;
                localStorage.setItem("userData", JSON.stringify(userData));
                console.log("All API calls completed. Navigating to another page...");
                // navigateToAnotherPage();
                navigate("/agent/orders");
                setLoading(false);
              })
              .catch(console.error);
              // console.log(open);
            
              setLoading(false);
              // 
              // fetchCartItems();
            } else {
              NotificationManager.error("Error", response.data.message, 3000);
              setLoading(false);
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.clear();
              navigate("/login");
            } else {
              NotificationManager.error(
                "Error",
                error.response.data.message,
                3000
              );
              setLoading(false);

            }
          }
          // NotificationManager.success("Success", "Payment Success", 3000);
        }
      }
    } else {
      paymentDeclined();
      setLoading(false);

    }

    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",
    ad_group_id: "",
    api_key: "",
    cpc:"",
  });
  const [adsload, setAdsLoad] = useState(false);
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/datacred`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      // console.log(response.data);
      let newFormData = { ...formData };
      response.data.data.forEach((item) => {
        // console.log(item);
        switch (item.name) {
          case "client_id":
            newFormData["client_id"] = item.value;
            break;
          case "client_secret":
            newFormData["client_secret"] = item.value;
            break;
          case "developer_token":
            newFormData["developer_token"] = item.value;
            break;

          case "refresh_token":
            newFormData["refresh_token"] = item.value;
            break;
          case "customer_id":
            newFormData["customer_id"] = item.value;
            break;
          case "manager_id":
            newFormData["manager_id"] = item.value;
            break;
          case "ad_group_id":
            newFormData["ad_group_id"] = item.value;
            break;
          case "api_key":
            newFormData["api_key"] = item.value;
            break;
          case "redirecturl":
            newFormData["redirecturl"] = item.value;
            break;
            case "cpc":
            newFormData["cpc"] = item.value;
            break;
          default:
            break;
        }
      });
      // console.log(newFormData);
      setFormData(newFormData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const handleTest = async (camp_name, zip_code, order_id, user_id, amount,city,state) => {
    setAdsLoad(true);
    console.log(amount);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ads/create`,
        {
          formData: formData,
          camp_name: camp_name,
          zip_code:zip_code,
          order_id: order_id,
          user_id: user_id,
          amount: amount,
          userdetails:userdetails,
          city:city,
          state:state
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setAdsLoad(false);

      console.log(response);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
      setAdsLoad(false);
    }
  };
  const paymentDeclined = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}payment-declined`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.error("Error", response.data.message, 3000);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {adsload ? <Loader /> : null}
      <NotificationContainer />
      <div className="form-group">
        <label htmlFor="cardElement" className="mt-3 mb-2">
          <b>Card Details</b>
        </label>
        <CardElement
          id="cardElement"
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#32325d",
                fontFamily: "Arial, sans-serif",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
              },
            },
          }}
          className="form-control"
        />
      </div>
      <Button
        type="submit"
        className="find-btn mt-3 w-100 rounded-pill"
        style={{ height: "45px" }}
        disabled={!stripe || loading}
      >
        {loading ? "Processing..." : "Pay"}
      </Button>
    </form>
  );
};

export default CheckoutForm;
