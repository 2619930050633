import React, { useEffect, useState } from "react";
import Agentlayout from "../../components/Agent/Agentlayout";
import "../../Style/Agents/agentfaq.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { Button } from "react-bootstrap";

const Agentsfaq = () => {
  const navigate = useNavigate();
  const [faqsList, setFaqsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterFaqs, setFilterFaqs] = useState([]);
  useEffect(() => {
    getFaqs();
  }, []);

  useEffect(() => {
    getFaqs();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);

    if (query.trim() === "") {
      setFaqsList(faqsList);
    } else {
      const filters = filterFaqs.filter(
        (faq) =>
          faq.question.toLowerCase().includes(query.toLowerCase()) ||
          faq.answer.toLowerCase().includes(query.toLowerCase())
      );
      setFaqsList(filters);
    }
  };
  const getFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent-faqs`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        setFaqsList(response.data.data);
        setFilterFaqs(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  return (
    <Agentlayout>
      <div className="faq-header-container">
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <div className="faq-header">
                <h3>Knowledge Base</h3>
                <Form
                  className="header-search"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Search the Help Center"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  {/* <Button onClick={handleSearch}>Search</Button> */}
                </Form>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row className="mt-2">
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <h2 className="text-center">Listelligent Tutorial</h2>
            </Col>
            <Col md={6}>
              <div className="p-2 text-center">
                <video
                  style={{
                    border: "2px solid #ddd",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  controls
                  width="100%"
                  height="350"
                  className="p-2"
                  // autoPlay
                  poster={require("../../../src/assets/agent-thumb.jpg")}
                >
                  <source
                    src={require("../../../src/assets/loom-video.mp4")}
                    type="video/mp4"
                  />
                </video>
                {/* <iframe
                  style={{
                    border: "2px solid #ddd",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  className="p-2"
                  width="100%"
                  height="350"
                  src="/How-to-claim-zip-code-Listelligent.mp4"
                  title="How to claim zip code."
                  frameBorder="0"
                  allow="accelerometer; autoplay=false; muted; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="faq-box mt-0">
                <div className="box-header">
                  <h5>FAQ for Agents</h5>
                </div>
                <hr></hr>
                <div className="box-content">
                  {/* <div className="p-2 text-center">                    
                    <video
                      controls
                      width="50%"
                      height="350"
                      autoplay
                      poster={require("../../../src/assets/video_thumbnail_3.webp")}
                    >
                      <source
                        src={require("../../../src/assets/video.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
                  <Accordion defaultActiveKey="0" flush>
                    {faqsList.map((item, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header className="acordian-btn">
                          {item.question}
                        </Accordion.Header>
                        <Accordion.Body>{item.answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className='popular-articles-container'>
                <Container>
                    <Row>
                        <Col>
                            <div className='popular-articles'>
                                <h3>Popular Articles</h3>
                                <ul>
                                    <li><Link>How to Add Reviews to Your Agent Profile</Link></li>
                                    <li><Link>How Do Expired Rate and Average Submission Time Affect My Ranking?</Link></li>
                                    <li><Link>How to Pay UpNest When a Referral Closes</Link></li>
                                    <li><Link>Is UpNest Available Across the U.S.?</Link></li>
                                    <li><Link>Do Most Agents Discount Their Commission?</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
    </Agentlayout>
  );
};

export default Agentsfaq;
