import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import "react-notifications/lib/notifications.css";
import Button from "react-bootstrap/Button";
import Loader from "../../components/Loader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const GoogleSetting = () => {
  const authToken = localStorage.getItem("token");
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",
    
    cpc: "",
    api_key: "",
  });
  const authCode = queryParams.get("refresh_token");
  useEffect(() => {
    getData();
  }, []);
  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}ads/datacred`,

        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      console.log(response.data);
      console.log(formData);

      let newFormData = { ...formData };
      response.data.data.forEach((item) => {
        console.log(item);
        switch (item.name) {
          case "client_id":
            newFormData["client_id"] = item.value;
            break;
          case "client_secret":
            newFormData["client_secret"] = item.value;
            break;
          case "developer_token":
            newFormData["developer_token"] = item.value;
            break;
          case "redirecturl":
            newFormData["redirecturl"] = item.value;
            break;
          case "refresh_token":
            newFormData["refresh_token"] = item.value;
            break;
          case "customer_id":
            newFormData["customer_id"] = item.value;
            break;
          case "manager_id":
            newFormData["manager_id"] = item.value;
            break;
          case "cpc":
            newFormData["cpc"] = item.value;
            break;
        
          case "api_key":
            newFormData["api_key"] = item.value;
            break;
          default:
            break;
        }
      });
      setFormData(newFormData);
      if (authCode) {
        setFormData({
          ...newFormData,
          ["refresh_token"]: authCode,
        });
        console.log("asdsadasd");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}ads/save`,
        {
          formData,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.success(
          "Ads Setting saved succesfully",
          response.data.message,
          3000
        );
        getData();
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      

      } else {
          NotificationManager.error("Error", error.response.data.message, 3000);
      }
      setLoading(false);
    }

    // Handle the form data as needed, e.g., send to an API endpoint
  };
  const isGenerateButtonVisible =
    formData.client_id && formData.client_secret && formData.redirecturl;

  const handleGenrate = async (event) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}gettoken`,
        {
          formData,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      // console.log(response);
      window.location.href = response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/login");
      } else {
        //   NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  return (
    <Dashboardlayout>
        {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <h4>Google ads configs</h4>
              </Card.Header>
              <Card.Body>
                <div className="dataTable" style={{ margin: "13px 0px" }}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Client Id</Form.Label>
                      <Form.Control
                        type="text"
                        name="client_id"
                        placeholder="Enter Google Client Id"
                        value={formData.client_id}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Client Secret</Form.Label>
                      <Form.Control
                        type="text"
                        name="client_secret"
                        placeholder="Enter Google Client Secret"
                        value={formData.client_secret}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Redirect url</Form.Label>
                      <Form.Control
                        type="text"
                        name="redirecturl"
                        placeholder="Enter Redirect url"
                        value={formData.redirecturl}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput5"
                    >
                      <Form.Label>Generate Refresh token</Form.Label>
                      <Form.Control
                        type="text"
                        name="refresh_token"
                        placeholder="Enter Refresh Token"
                        value={formData.refresh_token}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    {isGenerateButtonVisible && formData.refresh_token == "" ? (
                      <Button
                        type="button"
                        className="mb-3"
                        onClick={handleGenrate}
                      >
                        Generate
                      </Button>
                    ) : (
                      ""
                    )}
                                        <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput7"
                    >
                      <Form.Label>Api Key</Form.Label>
                      <Form.Control
                        type="text"
                        name="api_key"
                        placeholder="Enter Api Key"
                        value={formData.api_key}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput4"
                    >
                      <Form.Label>Developer Token</Form.Label>
                      <Form.Control
                        type="text"
                        name="developer_token"
                        placeholder="Enter Developer Token"
                        value={formData.developer_token}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput4"
                    >
                      <Form.Label>Customer ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="customer_id"
                        placeholder="Enter Customer ID"
                        value={formData.customer_id}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput4"
                    >
                      <Form.Label>Manager ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="manager_id"
                        placeholder="Enter Manager ID"
                        value={formData.manager_id}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput5"
                    >
                      <Form.Label>Cost per click</Form.Label>
                      <Form.Control
                        type="text"
                        name="cpc"
                        placeholder="Enter Cost per click ads"
                        value={formData.cpc}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Button type="submit">Save</Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* View Contact data modal */}
      </Container>
    </Dashboardlayout>
  );
};
export default GoogleSetting;
